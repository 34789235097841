import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import { checkFeatureLimit } from '../../../reducers/membership';
import { openModal } from '../../../reducers/modals';
import { clear, setSettings } from '../../../reducers/activeInvoice';
import { sendGTMEvent } from '../../../reducers/company';
import {
  decimalPrecision,
  country as countrySelector,
  localSettings as localSettingsSelector,
  companySelector,
  regime as regimeSelector,
} from '../../../selectors/company';
import {
  currency,
  itemsCount,
  total,
  noITBMS,
  items as itemsSelector,
  numeration as numerationSelector,
  client as clientSelector,
  discSubtotal,
} from '../../../selectors/activeInvoice';
import {
  electronicInvoicing,
  stationFENumberTemplate,
  stationInvoiceNumeration,
} from '../../../selectors/app';
import { hasPermissionTo } from '../../../selectors/auth';
import { getMainCurrency } from '../../../selectors/currencies';

import CleanInvoiceConfirm from './CleanInvoiceConfirm';
import { toast } from '../../../utils';
import {
  checkNumerationBlock,
  itemsHaveReferenceMaxExceeded,
  itemsInSaleHaveOutdatedProductKey,
} from './utils';
import { getColombiaUVT } from '../../../utils/enums/colombiaUVT';
import { isElectronicPOSDocumentAvailableSelector } from '../../../selectors/numerations';
import { COUNTRIES } from '../../../utils/enums/countries';
import { useConnectionStatus } from '../../../hooks/useConnectionStatus';
import { useApiAvailable } from '../../../hooks/useApiAvailable';
import { Tooltip } from '@alegradev/smile-ui-react';

const Actions = () => {
  const [confirm, setConfirm] = useState(false);
  const isOnline = useConnectionStatus();
  const isApiAvailable = useApiAvailable()
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  const count = useSelector(itemsCount);
  const client = useSelector(clientSelector);
  const decimal = useSelector(decimalPrecision);
  const mainCurrency = useSelector(getMainCurrency);
  const selectedCurrency = useSelector(currency);
  const items = useSelector(itemsSelector);
  const country = useSelector(countrySelector);
  const numeration = useSelector(numerationSelector);
  const regime = useSelector(regimeSelector);
  const stationNumeration = useSelector(stationInvoiceNumeration);
  const isElectronic = useSelector(electronicInvoicing(numeration));
  const showNoITBMSWarning = useSelector(noITBMS) && isElectronic;
  const currentTotal = useSelector(total);
  const invoiceDiscSubtotal = useSelector(discSubtotal);
  const localSettings = useSelector(localSettingsSelector);
  const feNumeration = useSelector(stationFENumberTemplate);
  const isElectronicPOSDocumentAvailable = useSelector(isElectronicPOSDocumentAvailableSelector);
  const { registryDate } = useSelector(companySelector);
  const noViewNumerationPermission = !can('index', 'number-templates');

  const prefix = !!get(selectedCurrency, 'symbol')
    ? get(selectedCurrency, 'symbol')
    : !!get(mainCurrency, 'symbol')
      ? get(mainCurrency, 'symbol')
      : '';

  const fmt = {
    prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  };

  const domCondition =
    country === 'republicaDominicana' &&
    isElectronic &&
    items
      .map((item) =>
        get(item, 'tax').some(
          (tax) => get(tax, 'type') === 'ITBIS' || get(tax, 'type') === 'EXENTO'
        )
      )
      .some((value) => value === false);

  const domClientNumerationCondition =
    country === 'republicaDominicana' &&
    (!!numeration
      ? get(numeration, 'prefix') === 'E31'
      : get(stationNumeration, 'prefix') === 'E31') &&
      !get(client, 'id');
  const spainClientCodition =
    country === 'spain' &&
    !!numeration &&
    get(numeration, 'documentType') === 'invoice' &&
    !get(client, 'id');

  const peruCondition =
    country === COUNTRIES.PERU &&
    !`${regime}`.includes('RUS') &&
    get(numeration, 'documentType') === 'saleTicket' &&
    get(client, 'identificationObject.type') === 'RUC';

  const peruDocumentCondition =
    country === COUNTRIES.PERU &&
    !`${regime}`.includes('RUS') &&
    get(numeration, 'documentType') !== 'saleTicket' &&
    get(client, 'identificationObject.type') !== 'RUC';

  const peruInvoiceCondition =
    country === COUNTRIES.PERU &&
    !`${regime}`.includes('RUS') &&
    get(numeration, 'documentType') === 'invoice' &&
    get(client, 'identificationObject.type') === 'RUC' &&
    !isElectronic;

  const criUserHasItemsInSaleWithOutdatedProductKey =
    country === 'costaRica' && itemsInSaleHaveOutdatedProductKey(items);
  const criUserHasItemsWithReferenceMaxExceeded =
    country === 'costaRica' &&
    isElectronic &&
    itemsHaveReferenceMaxExceeded(items, false);

  const isNumerationBlocked = checkNumerationBlock(
    numeration ?? stationNumeration,
    country,
    registryDate,
    false,
    isOnline,
    isApiAvailable
  );

  const handleSell = () => {
    if (
      currentTotal.toNumber() > 250000 &&
      country === 'republicaDominicana' &&
      !get(client, 'identification') &&
      get(numeration, 'prefix') === 'E32'
    ) {
      toast.error({
        title: I18n.get(
          'noClientIdentificationError',
          'Elige un cliente con identificación'
        ),
        subtitle: I18n.get(
          'noClientIdentificationErrorSubtitle',
          'En facturas con un valor superior a RD$ 250,000 debes elegir un cliente con RNC o cédula.'
        ),
      });
      throw new Error();
    }

    const _numeration = !!numeration ? numeration : stationNumeration;
    const colombiaUVT = getColombiaUVT();

    // if (country === 'colombia'
    //   && get(localSettings, 'automationFEEnabled', false)
    //   && get(_numeration, 'documentType', null) === 'saleTicket'
    //   && invoiceDiscSubtotal >= colombiaUVT?.UVT
    //   && !isElectronicPOSDocumentAvailable
    // ) {
    //   dispatch(setSettings({ numeration: feNumeration }));
    //   dispatch(
    //     checkFeatureLimit(['income', 'invoices'], () =>
    //       dispatch(openModal({ modal: 'invoice5UVT' }))
    //     )
    //   );
    // } else {
    //   if (
    //     country === 'spain' &&
    //     currentTotal.toNumber() >= 400 &&
    //     (numeration === null || numeration.documentType) !== 'invoice'
    //   ) {
    //     dispatch(openModal({ modal: 'changeToOrdinaryNumeration' }));
    //   } else {
    //     dispatch(
    //       checkFeatureLimit(['income', 'invoices'], () =>
    //         dispatch(openModal({ modal: 'invoice' }))
    //       )
    //     );
    //   }
    // }
    if (country === 'spain' &&
      currentTotal.toNumber() >= 400 &&
      (numeration === null || numeration.documentType !== 'invoice')) {

      dispatch(openModal({ modal: 'changeToOrdinaryNumeration' }));

    } else {

      dispatch(
        checkFeatureLimit(['income', 'invoices'], () =>
          dispatch(openModal({ modal: 'invoice' }))
        )
      );
    }

  };

  const getWarningSell = (country) => {
    if (
      (!isOnline || !isApiAvailable) &&
      get(numeration, 'isElectronic', false) &&
      [COUNTRIES.COLOMBIA, COUNTRIES.ARGENTINA, COUNTRIES.PERU, COUNTRIES.COSTA_RICA, COUNTRIES.PANAMA, COUNTRIES.REPUBLICA_DOMINICANA, COUNTRIES.MEXICO].includes(country)
    ) {
      return I18n.get("numerationElectronicOffline", "No puedes vender porque la numeración está en modo offline.");
    }

    if (noViewNumerationPermission)
      return I18n.get(
        'youShouldSelectANumeration',
        'Debes seleccionar una numeración, solicita a un administrador el permiso para ver el listado.'
      );

    switch (country) {
      case 'panama':
        return I18n.get(
          'noItbmsWarning.summary',
          'Parece que seleccionaste productos que no tienen impuestos ITBMS y debes validarlos para crear tu factura.'
        );
      case 'republicaDominicana':
        return I18n.get(
          'noItbisWarning.summary',
          'Parece que seleccionaste productos que no tienen impuestos ITBIS y debes validarlos para crear tu factura.'
        );
      case 'peru':
        if (peruDocumentCondition)
          return I18n.get(
            'identificationNotAvailable.new.summary',
            `Debes elegir una numeración de boletas porque el cliente tiene un tipo de identificación diferente a 'RUC'.`
          );
        return I18n.get(
          'rucNotAvailable.new.summary',
          `Para clientes con tipo de identificación 'RUC' debes elegir una numeración de facturas electrónicas.`
        );
      case 'costaRica':
        if (criUserHasItemsWithReferenceMaxExceeded)
          return I18n.get(
            'itemsInSaleExceededReference',
            'Alguno de tus productos en venta supera el máximo 20 caracteres para la referencia, editalo para continuar.'
          );
        return I18n.get(
          '',
          'Para vender debes editar el código de los productos pendientes de actualizar.'
        );
      default:
        return '';
    }
  };

  return (
    <div className='invoice-bottom__actions'>
      <div className='px-3'>
        <Tooltip
          visible={
            domCondition ||
            showNoITBMSWarning ||
            criUserHasItemsInSaleWithOutdatedProductKey ||
            peruCondition ||
            peruDocumentCondition ||
            peruInvoiceCondition ||
            criUserHasItemsWithReferenceMaxExceeded ||
            isNumerationBlocked ||
            noViewNumerationPermission
          }
          children={
            <div className='pay px-3'>
              <button
                id='invoice-button'
                className='btn btn-primary btn-sell-invoice d-flex justify-content-between'
                type='button'
                data-testid='sell-btn'
                disabled={
                  !count ||
                  domCondition ||
                  domClientNumerationCondition ||
                  spainClientCodition ||
                  showNoITBMSWarning ||
                  criUserHasItemsInSaleWithOutdatedProductKey ||
                  peruCondition ||
                  peruDocumentCondition ||
                  peruInvoiceCondition ||
                  criUserHasItemsWithReferenceMaxExceeded ||
                  isNumerationBlocked ||
                  noViewNumerationPermission

                }
                onClick={() => {
                  const _numeration = !!numeration
                    ? numeration
                    : stationNumeration;
                  try {
                    handleSell();
                    if (window.Cypress) return;
                    dispatch(
                      sendGTMEvent('invoice-creation-attempted', {
                        creationStatus: 'success',
                        isElectronicInvoicer: isElectronic,
                        documentType: get(_numeration, 'documentType', null),
                        isElectronicInvoice: get(
                          _numeration,
                          'isElectronic',
                          false
                        ),
                        currency: get(selectedCurrency, 'code', null),
                        invoiceSubtotal: invoiceDiscSubtotal,
                        invoiceTotal: !!currentTotal
                          ? currentTotal.toNumber()
                          : null,
                        totalItems: count,
                      })
                    );
                  } catch {
                    if (window.Cypress) return;
                    dispatch(
                      sendGTMEvent('invoice-creation-attempted', {
                        creationStatus: 'failure',
                        isElectronicInvoicer: isElectronic,
                        documentType: get(_numeration, 'documentType', null),
                        isElectronicInvoice: get(
                          _numeration,
                          'isElectronic',
                          false
                        ),
                        currency: get(selectedCurrency, 'code', null),
                        invoiceSubtotal: invoiceDiscSubtotal,
                        invoiceTotal: !!currentTotal
                          ? currentTotal.toNumber()
                          : null,
                        totalItems: count,
                      })
                    );
                  }
                }}
              >
                <div>{I18n.get('sell', 'vender')}</div>
                <div>{useSelector(total).toFormat(decimal, fmt)}</div>
              </button>
            </div>
          }
          overlay={getWarningSell(country)}
          width='full'
        />

        <div className='cancel px-3 mb-2'>
          <button
            className='btn btn-cancel-invoice w-100 d-flex justify-content-between'
            type='button'
            disabled={!count}
            onClick={() => setConfirm(true)}
          >
            <div>{`${count} ${count === 1
              ? I18n.get('item', 'producto')
              : I18n.get('items', 'productos')
              }`}</div>
            <div className='text-primary'>{I18n.get('cancel', 'cancelar')}</div>
          </button>
        </div>
      </div>
      <CleanInvoiceConfirm
        isOpen={confirm}
        onRequestClose={() => setConfirm(false)}
        onConfirm={() => {
          dispatch(clear());
          setConfirm(false);
        }}
      />
    </div>
  );
};

export default Actions;
