import { I18n } from 'aws-amplify';
import { get, capitalize } from 'lodash';
import { identificationTypesEnum } from '../../../../../../components/countriesData/costaRica/identificationTypes';
import { validateCreditLimit } from '../validations';

export const costaRicaValidation = (values) => {
  let errors = {};

  const identificationType = get(values, 'identification.type', '');
  const identificationNumber = get(values, 'identification.number', '');

  if (!identificationType)
    errors.identification = {
      ...errors.identification,
      type: capitalize(
        I18n.get(
          'selectTheIdentificationType',
          'seleccione el tipo de identificación'
        )
      ),
    };

  if (identificationType === 'CF' && identificationNumber.length < 9)
    errors.identification = {
      ...errors.identification,
      number:
        capitalize(
          I18n.get(
            'theMinimumSizeForThisFieldIs',
            'El tamaño minimo para este campo es de'
          )
        ) + ' 9.',
    };
  if (identificationType === 'CF' && identificationNumber.length > 9)
    errors.identification = {
      ...errors.identification,
      number:
        capitalize(
          I18n.get(
            'theMaximumSizeForThisFieldIs',
            'El tamaño maximo para este campo es de'
          )
        ) + ' 9.',
    };

  if (identificationType === 'CJ' && !/^[^-]+$/.test(identificationNumber))
    errors.identification = {
      ...errors.identification,
      number: capitalize(
        I18n.get(
          'theLegalIDMustBeWithoutHyphens',
          'La cédula jurídica debe estar sin guiones.'
        )
      ),
    };
  if (identificationType === 'CJ' && identificationNumber.length < 10)
    errors.identification = {
      ...errors.identification,
      number:
        capitalize(
          I18n.get(
            'theMinimumSizeForThisFieldIs',
            'El tamaño minimo para este campo es de'
          )
        ) + ' 10.',
    };
  if (identificationType === 'CJ' && identificationNumber.length > 10)
    errors.identification = {
      ...errors.identification,
      number:
        capitalize(
          I18n.get(
            'theMaximumSizeForThisFieldIs',
            'El tamaño maximo para este campo es de'
          )
        ) + ' 10.',
    };

  if (
    identificationType === 'DIMEX' &&
    !/^[1-9]+[0-9]*$/.test(identificationNumber)
  )
    errors.identification = {
      ...errors.identification,
      number: capitalize(
        I18n.get(
          'theDIMEXIsANumericalValue',
          'El DIMEX es un valor numérico, sin ceros al inicio y sin guiones.'
        )
      ),
    };
  if (identificationType === 'DIMEX' && identificationNumber.length < 11)
    errors.identification = {
      ...errors.identification,
      number:
        capitalize(
          I18n.get(
            'theMinimumSizeForThisFieldIs',
            'El tamaño minimo para este campo es de'
          )
        ) + ' 11.',
    };
  if (identificationType === 'DIMEX' && identificationNumber.length > 12)
    errors.identification = {
      ...errors.identification,
      number:
        capitalize(
          I18n.get(
            'theMaximumSizeForThisFieldIs',
            'El tamaño maximo para este campo es de'
          )
        ) + ' 12.',
    };

  if (identificationType === 'NITE' && !/^[^-]+$/.test(identificationNumber))
    errors.identification = {
      ...errors.identification,
      number: capitalize(
        I18n.get(
          'theNITEMustBeWithoutHyphens',
          'El NITE debe estar sin guiones.'
        )
      ),
    };
  if (identificationType === 'NITE' && identificationNumber.length < 10)
    errors.identification = {
      ...errors.identification,
      number:
        capitalize(
          I18n.get(
            'theMinimumSizeForThisFieldIs',
            'El tamaño minimo para este campo es de'
          )
        ) + ' 10.',
    };
  if (identificationType === 'NITE' && identificationNumber.length > 10)
    errors.identification = {
      ...errors.identification,
      number:
        capitalize(
          I18n.get(
            'theMaximumSizeForThisFieldIs',
            'El tamaño maximo para este campo es de'
          )
        ) + ' 10.',
    };

  if (identificationType === 'PE' && identificationNumber.length > 20)
    errors.identification = {
      ...errors.identification,
      number:
        capitalize(
          I18n.get(
            'theMaximumSizeForThisFieldIs',
            'El tamaño maximo para este campo es de'
          )
        ) + ' 20.',
    };

  if (!identificationNumber)
    errors.identification = {
      ...errors.identification,
      number: capitalize(I18n.get('enterAnID', 'Ingresa una identificación')),
    };

  const combinedAddress = get(values, 'address.combined', '');
  const neighborhood = get(values, 'address.neighborhood', '');
  const address = get(values, 'address.address', '');
  const foreignId = identificationType === identificationTypesEnum.FOREIGN_ID;

  if (!foreignId) {
    if (!combinedAddress)
      errors.address = {
        ...errors.address,
        combined: capitalize(
          I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
        ),
      };
    if (!neighborhood)
      errors.address = {
        ...errors.address,
        neighborhood: capitalize(
          I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
        ),
      };
  }
  if (!address)
    errors.address = {
      ...errors.address,
      address: capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      ),
    };

  const creditLimitError = validateCreditLimit(values);
  if (creditLimitError) {
    errors.creditLimit = creditLimitError;
  }

  return errors;
};
