import { isObject } from 'lodash';
import { basicPOSClientData } from '../../../utils';
import { COUNTRIES } from '../../../utils/enums/countries';

function isSameName(contact, defaultClient) {
  if (!defaultClient || !contact) return false;
  
  if (typeof defaultClient.name === 'string') {
    return defaultClient.name === contact.name;
  }

  if (isObject(defaultClient.name)) {
    const fullName = `${defaultClient.name.firstName} ${defaultClient.name.lastName}`;
    return fullName === contact.name;
  }

  return false;
}

function isSameIdentification(contact, defaultClient) {
  const contactId = contact?.identification?.number || contact?.identification;
  const defaultClientId = defaultClient?.identification?.number;

  return contactId && defaultClientId && contactId === defaultClientId;
}

function checkIfIsDefaultClient(contact, country) {
  const defaultClient = basicPOSClientData(country);

  if (country === COUNTRIES.ARGENTINA) {
    return (
      defaultClient?.name === contact?.name &&
      isSameIdentification(contact, defaultClient)
    );
  }

  return isSameName(contact, defaultClient) || isSameIdentification(contact, defaultClient);
}

export default checkIfIsDefaultClient;
