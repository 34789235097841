import { useEffect, useState } from 'react';
import { get } from 'lodash';
import { I18n } from '@aws-amplify/core';
import Header from '../common/NewHeader';
import { useHistory } from 'react-router-dom';
import Switch from '../../common/Switch';
import { hasPermissionTo } from '../../../selectors/auth'
import { useDispatch, useSelector } from 'react-redux';
import { companySelector, country as countrySelector, idCompanySelector, multicurrency as multicurrencySelector, tipsSelector } from '../../../selectors/company'
import { setUser } from '../../../reducers/auth';
import { Field, Form, useFormState } from "react-final-form";
import { renderSelect, renderField } from "../../forms/fields/V0/Fields"
import { billsCategoriesWithoutFilter, pasivesCategoriesWithoutFilter } from '../../../selectors/categories';
import { editCompanyValue } from '../General/utils';
import { APIGraphqlSelector } from '../../../selectors/app';
import alegraAPI from '../../../reducers/alegraAPI';
import { toast } from '../../../utils';

import { Icon } from '@alegradev/smile-ui-react';
import { setTip } from '../../../reducers/activeInvoice';
import { tipSelector } from '../../../selectors/activeInvoice';
import { sendNewGTMEvent } from '../../../reducers/company';
import { Tooltip } from '@alegradev/smile-ui-react';
import { COUNTRIES } from '../../../utils/enums/countries';
import { userIsElegibleForTip } from '../utils';
import { getDefaultCategoryIdToCreateCharge, getDefaultCategoryIdPurchases } from '../../../utils/tips';

const getHelpLink = (country) => {
  if (country === COUNTRIES.COSTA_RICA)
    return 'https://ayuda.alegra.com/es/crear-impuesto-de-servicio-pos-cri'

  return 'https://ayuda.alegra.com/es/activar-propinas-en-pos-col'
}

const validate = (values) => {
  const errors = {};
  if (!get(values, "tips.suggestedValue", false)) {
    errors.tips = { ...errors.tips, suggestedValue: I18n.get('requiredField', 'Campo requerido') }
  }
  if (!get(values, "tips.account", false)) {
    errors.tips = { ...errors.tips, account: I18n.get('requiredField', 'Campo requerido') }
  }
  if (values && isNaN(get(values, "tips.suggestedValue", null))) {
    errors.tips = { ...errors.tips, suggestedValue: I18n.get('invalidField', 'Campo inválido') }
  }

  return errors;
}

const isSwitchDisabled = (values, country) => {
  if (COUNTRIES.COSTA_RICA === country)
    return !get(values, 'tips.account', false) || !get(values, 'tips.accountPurchases', false)

  return !get(values, 'tips.account', false)
}

const AccountInputs = ({ loading, categories, categoriesPurchases, defaultCategory, defaultCategoryPurchases }) => {
  const country = useSelector(countrySelector)
  return (
    <div className='w-100 mt-4 d-flex'>
      <Field
        name="tips.account"
        disabled={loading}
        component={renderSelect}
        options={categories}
        className={`col-12 col-sm-6 p-0 pr-2 mb-0 tip-input ${loading ? 'disabled' : ''}`}
        helpTooltip={I18n.get('tips.accountHelp', 'Elige la cuenta contable para el registro de las propinas recaudadas')}
        label={I18n.get('tips.accountLabel', 'Cuenta contable para propinas')}
        defaultValue={defaultCategory}
        getOptionValue={option => option.id}
        getOptionLabel={option => `${option.name}`}
      />

      {
        country === COUNTRIES.COSTA_RICA && (
          <Field
            name="tips.accountPurchases"
            disabled={loading}
            component={renderSelect}
            options={categoriesPurchases}
            className={`col-12 col-sm-6 p-0 pl-2 mb-0 tip-input ${loading ? 'disabled' : ''}`}
            helpTooltip={I18n.get('tips.accountHelpPurchases', 'Elige la cuenta contable para el registro de las propinas recaudadas')}
            label={I18n.get('tips.accountPurchasesLabel', 'Cuenta contable para compras')}
            defaultValue={defaultCategoryPurchases}
            getOptionValue={option => option.id}
            getOptionLabel={option => `${option.name}`}
          />
        )
      }
    </div>
  )
}

const SellPreferencesFormBody = ({ tips, loading, setInputChange, suggested }) => {
  const { values } = useFormState();
  const country = useSelector(countrySelector);

  useEffect(() => {
    if (!values || get(values, "tips.suggestedValue", null) === null
      || get(values, "tips.account.id", null) === null
      || suggested === null
    ) return

    // verificamos si cambio algo en el form para habilitar el boton de guardar
    if (String(get(values, "tips.suggestedValue", null)) !== String(get(tips, "suggestedValue", null))
      || get(values, "tips.account.id", null) !== get(tips, "account", null)
      || suggested !== get(tips, "suggested", null)
      || country === COUNTRIES.COSTA_RICA && (
        get(values, "tips.accountPurchases.id", null) !== get(tips, "accountPurchases", null)
      )
    ) {
      setInputChange(true)
    } else {
      setInputChange(false)
    }

  }, [values, suggested, tips])

  return (
    <div className='d-flex flex-column flex-sm-row mt-4 form-tips-settings'>
      <Field
        type="text"
        name="tips.suggestedValue"
        disabled={loading}
        component={renderField}
        defaultValue={!!tips.suggestedValue ? tips.suggestedValue : 10}
        className={`col-12 col-sm-6 p-0 input-tips-settings tip-input ${loading ? 'disabled' : ''}`}
        helpTooltip={I18n.get('tips.suggestedPercentageHelp', 'Se calcula sobre el valor de venta antes de impuestos y puedes modificarlo al vender.')}
        label={I18n.get('tips.suggestedPercentageLabel', 'Porcentaje sugerido')}
      />
    </div>
  )
}

const SettingsTipsForm = ({ tips, loading, setInputChange, setSuggested, suggested }) => {
  const updateTipsSuggested = async (newValues) => {
    setSuggested(newValues.suggested)
  }

  return (
    <div className='w-100'>
      <div className="w-100 d-flex align-items-center justify-content-between mt-5">
        <div className='pr-5 mr-5 pr-sm-0 mr-sm-0'>
          <p className={`settings-body-title${loading ? '-disabled' : ''} text-capitalize-first mb-2`}>
            {I18n.get('tips.defaultSuggested', 'Sugerida por defecto')}
          </p>
          <p className={`settings-body-subtitle${loading ? '-disabled' : ''} text-capitalize-first mb-0`}>
            {I18n.get('tips.defaultSuggestedDesc', 'Agrega de forma automática la propina en cada venta y podrás editarla o eliminarla al vender')}
          </p>
        </div>
        <div className="d-flex align-items-center">
          {loading
            ? <>
              <Switch
                active={suggested}
                disabled
                large
              />
            </>
            : <Switch
              active={suggested}
              onChange={() => updateTipsSuggested({ suggested: !suggested })}
              large
            />}
        </div>
      </div>

      <SellPreferencesFormBody
        tips={tips}
        loading={loading}
        setInputChange={setInputChange}
        suggested={suggested}
      />
    </div>
  )
}

export default function SellPreferences() {
  const [inputChange, setInputChange] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(false);
  const history = useHistory();
  const { registryDate } = useSelector(companySelector);
  const tipsInvoice = useSelector(tipSelector)
  const can = useSelector(hasPermissionTo);
  const dispatch = useDispatch()
  const multicurrency = useSelector(multicurrencySelector);
  const country = useSelector(countrySelector);
  const tips = useSelector(tipsSelector);
  const companyId = useSelector(idCompanySelector);
  const [propertyLoading, setPropertyLoading] = useState({ multicurrency: false, tips: false });
  const APIGraphql = useSelector(APIGraphqlSelector);
  const [suggested, setSuggested] = useState(get(tips, 'suggested', false));

  const categories = useSelector(pasivesCategoriesWithoutFilter);
  const categoriesPurchases = useSelector(billsCategoriesWithoutFilter);
  const defaultCategoryId = !!tips.account ? tips.account : getDefaultCategoryIdToCreateCharge(categories, country, registryDate);
  const defaultCategoryIdPurchases = !!tips.accountPurchases ? tips.accountPurchases : getDefaultCategoryIdPurchases(categoriesPurchases, registryDate);

  const defaultCategory = categories.filter(cat => cat.id === defaultCategoryId)[0]
  const defaultCategoryPurchases = categoriesPurchases.filter(cat => cat.id === defaultCategoryIdPurchases)[0]

  useEffect(() => {
    if (!userIsElegibleForTip({ country, idCompany: companyId, registryDate }))
      history.push('/')
  }, [country, registryDate])

  useEffect(() => {
    if (get(tips, 'suggested', false) !== suggested) {
      setSuggested(get(tips, 'suggested', false))
    }
  }, [tips])

  const updateCompanyValue = async (newValues, property) => {
    setPropertyLoading(prev => ({ ...prev, [property]: true }))
    const response = await editCompanyValue(APIGraphql, newValues);
    if (response)
      dispatch(setUser(get(response, 'data.updateUser')))
    setPropertyLoading(prev => ({ ...prev, [property]: false }))
  }

  const switchTips = async (newValues, property, form) => {
    setPropertyLoading(prev => ({ ...prev, [property]: true }))
    setLoadingMessage(true)
    try {
      const categoryId = get(newValues, 'account.id')
      const categoryIdPurchases = country === COUNTRIES.COSTA_RICA
        ? get(newValues, 'accountPurchases.id')
        : categoryId

      if (!tips.additionalChargeId) {
        const aditionalCharge = await alegraAPI.post('/additional-charges/', {
          "name": I18n.get('tip', 'Propina'),
          "code": "01",
          "description": "Cantidad de dinero que se da voluntariamente para agradecer un servicio.",
          "percentage": 0,
          "categoryPurchasesId": categoryIdPurchases,
          "categorySalesId": categoryId,
          "status": "active",
          "companyId": companyId
        });

        const additionalChargeId = get(aditionalCharge.data, 'id', '0')
        if (additionalChargeId === '0') {
          throw new Error('No se pudo crear el cargo adicional')
        }
        newValues.additionalChargeId = additionalChargeId
      } else {
        await alegraAPI.put(`/additional-charges/${tips.additionalChargeId}`, {
          "categoryPurchasesId": categoryIdPurchases,
          "categorySalesId": categoryId,
        })
      }

      const tipsSettings = {
        localSettings: {
          tipEnabled: newValues.enabled,
          tipAdditionalChargeId: !tips.additionalChargeId ? newValues.additionalChargeId : tips.additionalChargeId,
          tipAccount: categoryId,
          tipAccountPurchases: categoryIdPurchases,
        }
      }

      const response = await editCompanyValue(APIGraphql, tipsSettings);
      if (response)
        dispatch(setUser(get(response, 'data.updateUser')))

      if (get(tipsInvoice, 'include', false)) {
        dispatch(setTip({ include: newValues.enabled }))
      }

      dispatch(sendNewGTMEvent('pos-tip-configured', {
        action: newValues.enabled ? 'activate' : 'deactivate',
      }))

      toast.success({
        title: I18n.get('savedChangesOnSellPreferences', 'Cambios guardados en tus preferencias de venta'),
        params: { autoClose: 3000 }
      })
      setInputChange(false)
    } catch (error) {
      toast.error({ title: I18n.get('updateRequiredSettingsError', 'Error al guardar los cambios'), params: { autoClose: 3000 } })
    } finally {
      setPropertyLoading(prev => ({ ...prev, [property]: false }))
      setLoadingMessage(false)
      if (!newValues.enabled) {
        form.change('tips.suggestedValue', tips.suggestedValue || 10)
      }
    }
  }

  const setLoadingTips = (newValue) => {
    setPropertyLoading({ tips: newValue })
  }

  const onSubmit = async (values) => {
    setLoadingTips(true)
    setLoadingMessage(true)
    try {
      const account = get(values, 'tips.account.id', null)
      const accountPurchases = country === COUNTRIES.COSTA_RICA
        ? get(values, 'tips.accountPurchases.id', null)
        : account

      const tipsSettings = {
        localSettings: {
          tipSuggested: suggested,
          tipSuggestedValue: values.tips.suggestedValue,
          tipAccount: account,
          tipAccountPurchases: accountPurchases,
        }
      }

      await alegraAPI.put(`/additional-charges/${tips.additionalChargeId}`, {
        "categoryPurchasesId": accountPurchases,
        "categorySalesId": account,
      })

      const response = await editCompanyValue(APIGraphql, tipsSettings);

      if (response)
        dispatch(setUser(get(response, 'data.updateUser')))

      if (get(tipsInvoice, 'type', '') === 'PERCENTAGE') {
        dispatch(setTip({ percentage: values.tips.suggestedValue }))
      }

      dispatch(setTip({ include: suggested }))

      toast.success({
        title: I18n.get('savedChangesOnSellPreferences', 'Cambios guardados en tus preferencias de venta'),
        params: { autoClose: 3000 }
      })
      setInputChange(false)
    } catch (error) {
      toast.error({ title: I18n.get('updateRequiredSettingsError', 'Error al guardar los cambios'), params: { autoClose: 3000 } })
    } finally {
      setLoadingTips(false)
      setLoadingMessage(false)
    }

  }

  return (
    <div className="settings-container d-flex flex-column">
      <Header
        title={I18n.get('sellPreferences', 'Preferencias de venta')}
        subtitle={I18n.get('sellPreferences.desc', 'Personaliza la moneda y los cobros adicionales que incluirás en tus facturas.')}
        info={!can('edit', 'company') && I18n.get(('userNotAllowed.company.edit', 'no tienes permisos suficientes para editar la información de la compañía'))}
      />
      {COUNTRIES.COLOMBIA === country &&
        <div className="sell-preferences-body__multicurrency d-flex flex-column align-items-center justify-content-between bg-white">
          <div className="w-100 d-flex align-items-center justify-content-between">
            <div>
              <p className={`settings-body-title${propertyLoading.multicurrency ? '-disabled' : ''} text-capitalize-first mb-2`}>
                {I18n.get('multicurrency', 'Multimoneda')}
              </p>
              <p className={`settings-body-subtitle${propertyLoading.multicurrency ? '-disabled' : ''} text-capitalize-first mb-0`}>
                {I18n.get('multicurrency.subtitle', 'Registra tus documentos de venta en diferentes monedas')}
              </p>
            </div>
            <div className="d-flex align-items-center">
              {propertyLoading.multicurrency
                ? <>
                  <Switch
                    active={!multicurrency}
                    disabled
                    large
                  />
                </>
                : <Switch
                  active={multicurrency}
                  disabled={multicurrency}
                  onChange={() => updateCompanyValue({ multicurrency: !multicurrency }, 'multicurrency')}
                  large
                />}
            </div>
          </div>
        </div>
      }
      <Form onSubmit={onSubmit} validate={(values) => validate(values)}>
        {({ handleSubmit, values, form, submitting, submitError, pristine, errors }) => (
          <form noValidate onSubmit={handleSubmit} className='w-100'>
            <div className={`sell-preferences-body__multicurrency d-flex flex-column align-items-center justify-content-between bg-white mt-5`}>
              {propertyLoading.tips && <span className="d-flex loading-text pr-3" style={{ fontSize: '12px' }}>
                <span>
                  {loadingMessage && I18n.get('savingChanges', 'Guardando tus cambios...')}
                </span>
                <Icon icon='loader-2' animated type='primary' />
              </span>}
              <div className="w-100 d-flex align-items-center justify-content-between">
                <div className='pr-5 mr-5 pr-sm-0 mr-sm-0' >
                  <p className={`settings-body-title${propertyLoading.tips ? '-disabled' : ''} text-capitalize-first mb-2`}>
                    {I18n.get('tips', 'Propinas')}
                  </p>
                  <p className={`settings-body-subtitle${propertyLoading.tips ? '-disabled' : ''} text-capitalize-first mb-0`}>
                    {I18n.get('tips.subtitle', 'Incluye el cobro de propina o servicio en tus documentos de venta')}
                  </p>
                </div>
                <div className="d-flex align-items-center">
                  <div className='tip-help-link'>
                    <Tooltip overlay={I18n.get('sellPreferences.tip.LinkDesc', 'Leer artículo sobre propinas')}>
                      <a href={getHelpLink(country)} target="_blank" rel="noreferrer">
                        <Icon icon='book' color='#000' />
                      </a>
                    </Tooltip>
                  </div>
                  {propertyLoading.tips
                    ? <>
                      <Switch
                        active={tips.enabled}
                        disabled
                        large
                      />
                    </>
                    :
                    (
                      <Tooltip
                        overlay={I18n.get('noSelectedAccountForTips', 'Elige una cuenta contable para poder activar la funcionalidad')}
                        visible={isSwitchDisabled(values, country)}
                      >
                        <Switch
                          className={`switch-tips ${isSwitchDisabled(values, country) ? 'disabled' : ''}`}
                          active={tips.enabled}
                          disabled={isSwitchDisabled(values, country)}
                          onChange={() => switchTips({
                            enabled: !tips.enabled,
                            account: get(values, 'tips.account'),
                            accountPurchases: get(values, 'tips.accountPurchases'),
                          }, 'tips', form)}
                          large
                        />
                      </Tooltip>
                    )
                  }
                </div>
              </div>

              <AccountInputs
                loading={propertyLoading.tips}
                categories={categories}
                categoriesPurchases={categoriesPurchases}
                defaultCategory={defaultCategory}
                defaultCategoryPurchases={defaultCategoryPurchases}
              />

              {tips.enabled && <SettingsTipsForm errors={errors} suggested={suggested} setSuggested={setSuggested} setInputChange={setInputChange} tips={tips} setLoadingTips={setLoadingTips} loading={propertyLoading.tips} />}

            </div>
            {(tips.enabled) &&
              <div className='button-back-to-home-container'>
                {
                  inputChange ?
                    <button style={{ marginTop: "10px" }} type="submit" className="btn btn-primary " disabled={submitting || pristine || propertyLoading.tips || !!get(errors, "tips", false)}>
                      {I18n.get('saveChanges', 'Guardar cambios')}
                    </button> :
                    <button type="button" onClick={() => { history.push('/') }} className="button-back-to-home" disabled={submitting || pristine || propertyLoading.tips}>
                      {I18n.get('goToCheckIn', 'Ir a facturar')} <Icon icon="arrow-bar-right" />
                    </button>
                }
              </div>
            }
          </form>
        )}
      </Form>
    </div>
  )
}