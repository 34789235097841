import React, { useEffect, useMemo, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Field, useForm } from 'react-final-form';
import { capitalize, get } from 'lodash';
import { Grid, Toast } from '@alegradev/smile-ui-react';

import { idTypes as identificationsColombia } from '../../../../../components/countriesData/colombia/idTypes';
import identificationsCostaRica, {
  identificationTypesEnum,
} from '../../../../../components/countriesData/costaRica/identificationTypes';
import identificationsPeru from '../../../../../components/countriesData/peru/identificationTypes';
import identificationsArgentina from '../../../../../components/countriesData/argentina/identificationTypes';
import identificationsDominicana from '../../../../../components/countriesData/republicaDominicana/identificationTypes';
import identificationsPanama from '../../../../../components/countriesData/panama/identificationTypes';
import identificationsSpain from '../../../../../components/countriesData/spain/identificationTypes';
import kindOfPersonOptionsPanama from '../../../../../components/countriesData/panama/kindOfPerson';
import { COUNTRIES } from '../../../../../utils/enums/countries';

import { renderField } from '../../../../../components/forms/fields/V2';
import { canScrappingByCountry, useContactForm } from '../FormProvider';
import useConnectionStatus from '../../../../../hooks/useConnectionStatus/hook';

const identificationTypeLabel = () =>
  I18n.get('identificationType', 'Tipo de identificación');
const identificationLabel = () => I18n.get('identification', 'identificación');

const spainIdentificationLabel = (props) => {
  const identificationType = get(props, 'values.identification.type', '');

  if (identificationType === 'NIF') {
    return I18n.get('nif', 'NIF');
  }

  if (identificationType === 'NIE') {
    return I18n.get('nie', 'NIE');
  }

  if (identificationType === 'DNI') {
    return I18n.get('dni', 'DNI');
  }

  if (identificationType === 'EXT') {
    return I18n.get('identificationNumber', 'Número de identificación');
  }

  const identificationLabel = get(
    props,
    'values.identification.type.abbreviature',
    I18n.get('identificationNumber', 'Número de identificación')
  );
  return identificationLabel;
};

export const calculateDV = (identificationNumber, change) => {
  const id = identificationNumber;
  let dv = null;

  if (!!id && id.length <= 15) {
    const primeNumbers = [
      3, 7, 13, 17, 19, 23, 29, 37, 41, 43, 47, 53, 59, 67, 71,
    ];
    let totalSum = 0;

    for (let i = 0; i < id.length; i++) {
      totalSum += id[i] * primeNumbers[id.length - i - 1];
    }

    const mod = totalSum % 11;

    dv = [0, 1].indexOf(mod) !== -1 ? mod : 11 - mod;
  }

  change('identification.dv', dv);
};

const colombiaIdentification = (props) => {
  const { Col } = Grid;
  const { handleClientSearch, searchingClient } = useContactForm();
  const { change } = useForm();
  const online = useConnectionStatus();
  const identificationType = get(props, 'values.identification.type', '');
  const identificationNumber = get(props, 'values.identification.number', '');
  const country = get(props, 'country', '');
  const options = identificationsColombia(props.isElectronic);

  const renderIdentificationField = useMemo(() => {
    switch (identificationType) {
      case 'NIT':
        return (
          <>
            <Col xs={4} sm={10}>
              <Field
                name='identification.number'
                component={renderField}
                data-testid='client-id-input'
                label={identificationLabel()}
                loading={searchingClient}
                required
                onChange={(event) => {
                  change('identification.number', event?.target?.value);
                  calculateDV(event?.target?.value, change);
                }}
                helpPopover={
                  canScrappingByCountry(country, identificationType, online)
                    ? {
                        title: I18n.get('autocompleteTestTitle', ''),
                        description: I18n.get(
                          'autocompleteTestDescription',
                          ''
                        ),
                      }
                    : undefined
                }
                debounced
                debouncedDelay={1000}
                onChangeDebounced={(value) => !!value && handleClientSearch()}
                country={country}
              />
            </Col>
            <Col xs={2} sm={2}>
              <Field
                name='identification.dv'
                component={renderField}
                loading={searchingClient}
                type='text'
                label={I18n.get('dv', 'DV')}
                disabled
              />
            </Col>
          </>
        );

      case 'CC':
        return (
          <Col xs={6} sm={12}>
            <Field
              name='identification.number'
              component={renderField}
              data-testid='client-id-input'
              type='number'
              label={identificationLabel()}
              loading={searchingClient}
              debounced
              helpPopover={
                canScrappingByCountry(country, identificationType, online)
                  ? {
                      title: I18n.get(
                        'autocompleteTestTitle',
                        'Prueba el autocompletado ✨'
                      ),
                      description: I18n.get(
                        'autocompleteTestDescription',
                        'Escribe el número de identificación de tu cliente y obtén sus datos de la DIAN.'
                      ),
                    }
                  : undefined
              }
              debouncedDelay={1000}
              required
              value={identificationNumber}
              onChange={(event) => [
                change('identification.number', event?.target?.value),
              ]}
              onChangeDebounced={(value) => !!value && handleClientSearch()}
            />
          </Col>
        );

      default:
        return (
          <Col xs={6} sm={12}>
            <Field
              name='identification.number'
              component={renderField}
              data-testid='client-id-input'
              type='text'
              label={identificationLabel()}
              value={identificationNumber}
              loading={searchingClient}
              onChange={(event) => [
                change('identification.number', event?.target?.value),
              ]}
              onChangeDebounced={(value) => !!value && handleClientSearch()}
              helpPopover={
                canScrappingByCountry(country, identificationType, online)
                  ? {
                      title: I18n.get(
                        'autocompleteTestTitle',
                        'Prueba el autocompletado ✨'
                      ),
                      description: I18n.get(
                        'autocompleteTestDescription',
                        'Escribe el número de identificación de tu cliente y obtén sus datos de la DIAN.'
                      ),
                    }
                  : undefined
              }
              debounced
              debouncedDelay={1000}
              required
              country={country}
            />
          </Col>
        );
    }
  }, [identificationType]);

  return (
    <>
      <Col sm={12} xs={6}>
        <Field
          name='identification.type'
          component={renderField}
          fieldType='select'
          data-testid='client-id-type-input'
          label={identificationTypeLabel()}
          options={options?.map((option) => ({
            text:
              option.key === 'FOREIGN_NIT'
                ? option.value
                : option.abbreviature + ' - ' + option.value,
            value: option.key,
          }))}
          required
        />
      </Col>
      {renderIdentificationField}
    </>
  );
};

const costaRicaIdentification = (props) => {
  const { Col } = Grid;
  const { handleClientSearch, searchingClient } = useContactForm();
  const identificationType = get(props, 'values.identification.type', '');
  const { change } = useForm();

  return (
    <>
      {identificationType === identificationTypesEnum.FINAL_CONSUMER && (
        <Col xs={12}>
          <Toast
            title=''
            description={I18n.get(
              'finalConsumerIdenticationSelected',
              `Para contactos tipo <b>“Consumidor final”</b>, solo puedes crear tiquetes electrónicos.`
            )}
            shadow={false}
            type='info'
          />
        </Col>
      )}

      <Col xs={6} sm={12}>
        <Field
          name='identification.number'
          component={renderField}
          data-testid='client-id-input'
          type='text'
          label={identificationLabel()}
          loading={searchingClient}
          onChange={(event) => {
            change('identification.number', event?.target?.value);
          }}
          onChangeDebounced={() => handleClientSearch()}
          debounced
          debouncedDelay={1000}
          required
        />
      </Col>

      <Col xs={6} sm={12}>
        <Field
          name='identification.type'
          component={renderField}
          fieldType='select'
          data-testid='client-id-type-input'
          options={identificationsCostaRica.map((option) => ({
            text: option.value,
            value: option.key,
          }))}
          placeholder={I18n.get('select', 'Seleccionar')}
          label={identificationTypeLabel()}
          required
        />
      </Col>
    </>
  );
};

const argentinaIdentification = (props) => {
  const { Col } = Grid;
  const identificationType = get(props, 'values.identification.type', '');
  const { handleClientSearch, searchingClient } = useContactForm();
  const { change } = useForm();

  return (
    <>
      <Col xs={6} sm={12}>
        <Field
          name='identification.type'
          component={renderField}
          fieldType='select'
          data-testid='client-id-type-input'
          options={identificationsArgentina.map((option) => ({
            text: option.value,
            value: option.key,
          }))}
          label={identificationTypeLabel()}
          required
        />
      </Col>

      {identificationType === 'CUIT' ? (
        <Col xs={6} sm={12}>
          <Field
            name='identification.number'
            component={renderField}
            data-testid='client-id-input'
            loading={searchingClient}
            type='text'
            label={identificationLabel()}
            required
            onChange={(event) => {
              change('identification.number', event?.target?.value);
            }}
            onChangeDebounced={(value) => !!value && handleClientSearch()}
            debounced
            debouncedDelay={1000}
          />
        </Col>
      ) : (
        <Col xs={6} sm={12}>
          <Field
            name='identification.number'
            component={renderField}
            loading={searchingClient}
            data-testid='client-id-input'
            type='text'
            label={identificationLabel()}
            onChange={(event) => {
              change('identification.number', event?.target?.value);
            }}
            onChangeDebounced={(value) => !!value && handleClientSearch()}
            debounced
            debouncedDelay={1000}
            required
          />
        </Col>
      )}
    </>
  );
};

const peruIdentification = (props) => {
  const { Col } = Grid;
  const { handleClientSearch, searchingClient } = useContactForm();
  const identificationType = get(props, 'values.identification.type', '');
  const { change } = useForm();

  return (
    <>
      <Col xs={6} sm={12}>
        <Field
          name='identification.number'
          component={renderField}
          data-testid='client-id-input'
          type='text'
          label={identificationLabel()}
          loading={searchingClient}
          onChange={(event) => {
            change('identification.number', event?.target?.value);
          }}
          onChangeDebounced={(value) => !!value && handleClientSearch()}
          debounced
          debouncedDelay={1000}
          required
        />
      </Col>

      <Col xs={6} sm={12}>
        <Field
          name='identification.type'
          component={renderField}
          fieldType='select'
          data-testid='client-id-type-input'
          options={identificationsPeru?.map((option) => ({
            text: `${option.abbreviature} - ${option.value}`,
            value: option.key,
          }))}
          label={identificationTypeLabel()}
          required
        />
      </Col>
    </>
  );
};

const dominicanaIdentification = (props) => {
  const { Col } = Grid;
  const isElectronic = get(props, 'isElectronic', '');
  const identificationType = get(props, 'values.identification.type', '');
  const { handleClientSearch, searchingClient } = useContactForm();
  const { change } = useForm();

  const getDOMIdentificationLabel = (identificationType) => {
    switch (identificationType) {
      case 'RNC':
        return I18n.get('RNCNumber', 'Número de RNC');
      case 'CED':
        return I18n.get('CEDNumber', 'Número de Cedula');
      case 'IE':
        return I18n.get('number', 'Número');
      default:
        return I18n.get('RNCorCED', 'RNC o Cédula');
    }
  };

  return (
    <>
      <Col xs={6} sm={12}>
        <Field
          name='identification.number'
          component={renderField}
          data-testid='client-id-input'
          type='text'
          label={getDOMIdentificationLabel(identificationType)}
          loading={searchingClient}
          onChange={(event) => {
            change('identification.number', event?.target?.value);
          }}
          onChangeDebounced={(value) => !!value && handleClientSearch()}
          debounced
          debouncedDelay={1000}
          required={!!isElectronic || identificationType !== ''}
        />
      </Col>

      <Col xs={6} sm={12}>
        <Field
          name='identification.type'
          component={renderField}
          fieldType='select'
          placeholder={I18n.get('select', 'Seleccionar')}
          data-testid='client-id-type-input'
          options={identificationsDominicana?.map((option) => ({
            text: option.value,
            value: option.key,
          }))}
          label={identificationTypeLabel()}
        />
      </Col>
    </>
  );
};

const panamaIdentification = (props) => {
  const { Col } = Grid;
  const options = identificationsPanama;
  const identificationType = get(props, 'values.identification.type', '');
  const kindOfPerson = get(props, 'values.identification.kindOfPerson', '');
  const isElectronic = get(props, 'values.isElectronic', '');
  const { handleClientSearch, searchingClient } = useContactForm();
  const { change } = useForm();

  const isRequiredField = () => {
    if (!identificationType && !kindOfPerson && !isElectronic) return false;
    if (identificationType === 'FINAL_CONSUMER') return false;
    return true;
  };

  useEffect(() => {
    if (
      identificationType !== 'TAXPAYER' ||
      identificationType !== 'FINAL_CONSUMER'
    ) {
      change('identification.kindOfPerson', '');
    }
  }, [identificationType]);

  return (
    <>
      <Col xs={6} sm={12}>
        <Field
          name='identification.type'
          component={renderField}
          fieldType='select'
          data-testid='client-id-type-input'
          placeholder={I18n.get('select', 'Seleccionar')}
          label={identificationTypeLabel()}
          options={options?.map((option) => ({
            text: option.value,
            value: option.key,
          }))}
          required={props.isElectronic}
        />
      </Col>
      {(identificationType === 'FOREIGN' ||
        identificationType === 'FOREIGN_COMPANY') && (
        <Col xs={6} sm={12}>
          <Field
            name='identification.passport'
            component={renderField}
            data-testid='client-id-input'
            type='text'
            label={
              identificationType === 'FOREIGN'
                ? I18n.get('passport', 'Pasaporte')
                : identificationLabel()
            }
            loading={searchingClient}
            onChange={(event) => {
              change('identification.passport', event?.target?.value);
            }}
            onChangeDebounced={(value) => !!value && handleClientSearch()}
            debounced
            debouncedDelay={1000}
            required
          />
        </Col>
      )}

      {identificationType !== 'FOREIGN' &&
        identificationType !== 'FOREIGN_COMPANY' && (
          <>
            <Col xs={6} sm={12}>
              <Field
                name='identification.kindOfPerson'
                component={renderField}
                fieldType='select'
                label={capitalize(I18n.get('kindOfPerson', 'tipo de persona'))}
                placeholder={capitalize(I18n.get('select', 'Seleccionar'))}
                options={kindOfPersonOptionsPanama(identificationType)?.map(
                  (option) => ({
                    text: option.value,
                    value: option?.key,
                  })
                )}
                required={props.isElectronic ? isRequiredField() : false}
              />
            </Col>
            <Col xs={9} sm={9}>
              <Field
                name='identification.number'
                component={renderField}
                data-testid='client-name-input'
                type='text'
                label={
                  identificationType === 'FINAL_CONSUMER'
                    ? I18n.get('rucOrCc', 'RUC o cédula')
                    : I18n.get('ruc', 'RUC')
                }
                loading={searchingClient}
                onChange={(event) => {
                  change('identification.number', event?.target?.value);
                }}
                onChangeDebounced={(value) => !!value && handleClientSearch()}
                debounced
                debouncedDelay={1000}
                required={isRequiredField()}
              />
            </Col>
            <Col xs={3}>
              <Field
                name='identification.dv'
                component={renderField}
                type='text'
                label={I18n.get('dv', 'DV')}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]+/g, '');
                  change(
                    'identification.dv',
                    value.length <= 2 ? value : value.slice(0, 2)
                  );
                }}
                disabled={searchingClient}
                isLoading={searchingClient}
                required={isRequiredField()}
              />
            </Col>
          </>
        )}
    </>
  );
};

const spainIdentification = (props) => {
  const { Col } = Grid;
  const options = identificationsSpain;
  const identificationType = get(props, 'values.identification.type', '');
  const { handleClientSearch, searchingClient } = useContactForm();
  const { change } = useForm();

  return (
    <>
      <Col xs={6} sm={12}>
        <Field
          name='identification.type'
          component={renderField}
          fieldType='select'
          data-testid='client-id-type-input'
          label={identificationTypeLabel()}
          options={options?.map((option) => ({
            text: option.value,
            value: option.key,
          }))}
          required={true}
        />
      </Col>

      <Col xs={6} sm={12}>
        <Field
          name='identification.number'
          component={renderField}
          data-testid='client-id-input'
          type='text'
          label={spainIdentificationLabel(props)}
          loading={searchingClient}
          onChange={(event) => {
            change('identification.number', event?.target?.value);
          }}
          onChangeDebounced={(value) => !!value && handleClientSearch()}
          debounced
          debouncedDelay={1000}
          required
        />
      </Col>
    </>
  );
};

const mexicoIdentification = (props) => {
  const { Col } = Grid;
  const thirdType = get(props, 'values.thirdType', '');
  const { change } = useForm();
  const { handleClientSearch, searchingClient } = useContactForm();
  const MEXICO_FOREIGN_DEFAULT_RFC = 'XEXX010101000';

  useEffect(() => {
    if (thirdType === 'FOREIGN') {
      props.form.change('rfc', MEXICO_FOREIGN_DEFAULT_RFC);
    } else {
      if (get(props, 'values.rfc', '') === MEXICO_FOREIGN_DEFAULT_RFC)
        props.form.change('rfc', '');
    }
  }, [thirdType]);

  return (
    <Col xs={6} sm={12}>
      <Field
        name='rfc'
        component={renderField}
        type='text'
        label={I18n.get('rfc', 'RFC')}
        required
        disabled={thirdType === 'FOREIGN'}
        loading={searchingClient}
        onChange={(event) => {
          change('rfc', event?.target?.value);
        }}
        onChangeDebounced={(value) => !!value && handleClientSearch()}
        debounced
        debouncedDelay={1000}
      />
    </Col>
  );
};

const defaultIdentification = (props) => {
  const { Col } = Grid;
  const { handleClientSearch, searchingClient } = useContactForm();
  const { change } = useForm();

  return (
    <Col xs={12} sm={12}>
      <Field
        name='identification.number'
        component={renderField}
        data-testid='client-id-input'
        label={identificationLabel()}
        loading={searchingClient}
        onChange={(event) => {
          change('identification.number', event?.target?.value);
        }}
        onChangeDebounced={(value) => !!value && handleClientSearch()}
        debounced
        debouncedDelay={1000}
      />
    </Col>
  );
};

const renderIdentification = (props) => {
  const [searchingId, setSearchingId] = useState(false);
  const identificationProps = { ...props, searchingId, setSearchingId };

  switch (props.country) {
    case COUNTRIES.COLOMBIA:
      return colombiaIdentification(identificationProps);
    case COUNTRIES.COSTA_RICA:
      return costaRicaIdentification(identificationProps);
    case COUNTRIES.PERU:
      return peruIdentification(identificationProps);
    case COUNTRIES.ARGENTINA:
      return argentinaIdentification(identificationProps);
    case COUNTRIES.REPUBLICA_DOMINICANA:
      return dominicanaIdentification(identificationProps);
    case COUNTRIES.PANAMA:
      return panamaIdentification(identificationProps);
    case COUNTRIES.SPAIN:
      return spainIdentification(identificationProps);
    case COUNTRIES.MEXICO:
      return mexicoIdentification(identificationProps);
    default:
      return defaultIdentification(identificationProps);
  }
};

export default renderIdentification;
