import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import OfflineStatus from './items/OfflineStatus';
import Station from './items/Station';
import Apps from './items/Apps';
import Menu from './items/Menu/Menu';
import Help from './items/Help/V0/Help';
import DownloadIBDInfo from './items/DownloadIDBInfo';
import LoadIBDInfo from './items/LoadIDBInfo';
import { idCompanySelector } from '../../selectors/company';
import { HeaderProvider } from './context/header.context';
import HamburgerMenu from './items/Hamburguer';
import IntermittenciesStatus from './items/intermittenciesStatus';

const companiesAllowedToDownload = [
  '707604',
  '668422',
  '1313620'
];

let Header = ({ location }) => {
  const companyId = useSelector(idCompanySelector);

  return (
    <HeaderProvider>
      <div className='custom-header d-flex justify-content-between align-items-center px-2'>
        <HamburgerMenu location={location} />

        <ul className='custom-header-items'>
          {/* <GlobalLoadingIndicator /> */}
          <IntermittenciesStatus />

          {companiesAllowedToDownload.includes(companyId) && (
            <>
              <LoadIBDInfo />

              <DownloadIBDInfo />
            </>
          )}

          <OfflineStatus />

          <Station />

          <Help />

          <Apps />

          <Menu />
        </ul>
      </div>
    </HeaderProvider>
  );
};

Header = withRouter(Header);

Header.propTypes = {
  location: PropTypes.object,
};

export default Header;
