import { Select, Tooltip, Grid } from '@alegradev/smile-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { hasPermissionTo } from '../../../../selectors/auth';
import { country as countrySelector } from '../../../../selectors/company';
import {
  clientsFilterText,
  invoiceClients,
} from '../../../../selectors/clients';
import { filter } from '../../../../reducers/clients';
import { stationInvoiceNumeration } from '../../../../selectors/app';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useConnectionStatus from '../../../../hooks/useConnectionStatus/hook';
import checkIfIsDefaultClient from '../../../../pages/contacts/utils/isDefaultClient';
import { debounce, get } from 'lodash';
import { openCreateClientSideModal } from './utils/openCreateClient';
import { I18n } from 'aws-amplify';
import { basicPOSClientData } from '../../../../utils';
import {
  client as clientSelector,
  numeration as numerationSelector,
} from '../../../../selectors/activeInvoice';
import { MEXICO_DEFAULT_RFC } from '../../../countriesData/mexico/rfc';

import {
  renderClientIdentification,
  renderClientName,
} from './utils/parseClientInfo';
import { COUNTRIES } from '../../../../utils/enums/countries';
import { setClient } from '../../../../reducers/activeInvoice';
import { isCreateClientAvailable } from './hooks/isCreateClientAvailable';

const ClientSelector = () => {
  const { Wrapper } = Grid;
  const country = useSelector(countrySelector);
  const can = useSelector(hasPermissionTo);
  const dispatch = useDispatch();
  const clients = useSelector(invoiceClients);
  const client = useSelector(clientSelector);
  const numeration = useSelector(numerationSelector);
  const stationNumeration = useSelector(stationInvoiceNumeration);

  const [loading, setLoading] = useState(false);
  const searchFilter = useSelector(clientsFilterText);

  const [inputSearchValue, setInputSearchValue] = useState('');

  const ref = useRef();
  const isOnline = useConnectionStatus();

  useEffect(() => {
    if (searchFilter && searchFilter !== inputSearchValue)
      setInputSearchValue(searchFilter);
  }, [searchFilter]);

  useEffect(() => {
    ref.current = true;
    return () => (ref.current = false);
  }, []);

  const search = useCallback(
    async (text = '') => {
      if (ref.current) setLoading(true);

      await dispatch(filter({ text, limit: 35 }));

      if (ref.current) setLoading(false);
    },
    [dispatch]
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (can('view', 'contacts')) search(inputSearchValue);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [inputSearchValue, 500]);

  const spainClientCodition =
    country === 'spain' &&
    !!numeration &&
    get(numeration, 'documentType') === 'invoice' &&
    !get(client, 'id');

  const domClientNumerationCondition =
    country === 'republicaDominicana' &&
    (!!numeration
      ? get(numeration, 'prefix') === 'E31'
      : get(stationNumeration, 'prefix') === 'E31') &&
    !get(client, 'id');

  const isCreationAvailable = isCreateClientAvailable();

  const getClient = useMemo(() => {
    if (client && client?.id) return client;

    return basicPOSClientData(
      country,
      !!numeration ? numeration : stationNumeration
    );
  }, [client, numeration, stationNumeration]);

  useEffect(() => {
    if (!client) dispatch(setClient(getClient));
  }, [client]);

  const getSelectInfo = useMemo(() => {
    const currentClient = getClient;
    if (
      country === COUNTRIES.MEXICO &&
      currentClient?.identification === MEXICO_DEFAULT_RFC
    ) {
      return I18n.get(
        '',
        'Ten en cuenta que si seleccionas un cliente, se le creará una factura de venta en lugar de un ticket'
      );
    }

    return null;
  }, [client]);

  const options = useMemo(() => {
    if (can('view', 'contacts')) {
      return clients?.map((client) => {
        return {
          ...client,
          trailing:
            isOnline &&
            !checkIfIsDefaultClient(client, country) &&
            can('edit', 'contacts') &&
            get(client, 'status') === 'active'
              ? {
                  icon: 'pencil',
                  onClick: () =>
                    openCreateClientSideModal({ contact: client, dispatch }),
                }
              : undefined,
        };
      });
    }

    return [];
  }, [clients, can, isOnline]);

  return (
    <Wrapper fullWidth extraClasses='invoice-top__client-select'>
      <div data-testid='select-client-invoice'>
        <Tooltip
          visible={domClientNumerationCondition || spainClientCodition}
          overlay='Es necesario asignar un contacto en la factura'
          width='full'
        >
          <Select
            value={getClient}
            options={options}
            isSearchable={can('view', 'contacts')}
            isLoading={loading}
            isClearable={!checkIfIsDefaultClient(getClient, country)}
            placeholder={I18n.get('selectAClient', 'Selecciona un cliente')}
            noOptionsMessage={() =>
              can('view', 'contacts')
                ? clients.length === 0
                  ? I18n.get(
                      'createClientToSelect',
                      'Crea un nuevo cliente para seleccionarlo'
                    )
                  : I18n.get(
                      'createThisNewClientToSelect',
                      'Crea este nuevo cliente para seleccionarlo'
                    )
                : I18n.get(
                    'userNotAllowed.contacts.view',
                    'No tienes permiso para ver el detalle de contacto. Habla con tu administrador para que te habilite el permiso y así puedas usar el POS correctamente.'
                  )
            }
            hasError={domClientNumerationCondition || spainClientCodition}
            isDisabled={!isCreationAvailable}
            onChange={(client) =>
              dispatch(
                setClient({
                  ...client,
                  trailing: {},
                })
              )
            }
            selectInfo={getSelectInfo}
            onInputChange={useMemo(
              () =>
                debounce((value) => {
                  if (can('view', 'contacts')) search(value);
                }, 350),
              [can, search]
            )}
            cacheOptions={false}
            getOptionLabel={(option) =>
              `${renderClientName(option)}${renderClientIdentification(
                option,
                country
              )}`
            }
            trailing={
              isOnline &&
              !checkIfIsDefaultClient(getClient, country) &&
              can('edit', 'contacts') &&
              get(getClient, 'status') === 'active'
                ? {
                    icon: 'pencil',
                    onClick: () =>
                      openCreateClientSideModal({
                        contact: getClient,
                        dispatch,
                      }),
                  }
                : undefined
            }
          />
        </Tooltip>
      </div>
    </Wrapper>
  );
};

export default ClientSelector;
