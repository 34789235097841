import { capitalize } from 'lodash';
import { I18n } from '@aws-amplify/core';
import { TableCellRegular, TableCellStatus } from '@alegradev/smile-ui-react';

export const contactMainColumns = [
  {
    Header: capitalize(I18n.get('name', 'Nombre')),
    accessor: 'name',
    partiblePosition: 'top-left',
    width: '25%',
    Cell: ({ value }) => <TableCellRegular value={value} />,
  },
  {
    Header: capitalize(I18n.get('identification', 'Identificación')),
    accessor: 'identification',
    partiblePosition: 'bottom-left',
    width: '25%',
    Cell: ({ value }) => <TableCellRegular value={value} />,
  },
  {
    Header: capitalize(I18n.get('phone-mobile', 'Celular/móvil')),
    accessor: 'phonePrimary',
    width: '25%',
    Cell: ({ value }) => <TableCellRegular value={value} />,
  },
  {
    Header: capitalize(I18n.get('status', 'Estado')),
    accessor: 'status',
    width: '25%',
    Cell: ({ value }) => (
      <TableCellStatus
        active={value === 'active' ? true : false}
        activeText={I18n.get('active', 'Activado')}
        inactiveText={I18n.get('inactive', 'Desactivado')}
      />
    ),
  },
];
