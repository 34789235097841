import React, { useState } from 'react';
import PropsTypes from "prop-types";
import { Field, Form } from 'react-final-form';
import { renderSelect } from '../../forms/fields/V0/Fields';
import { capitalize, get } from 'lodash';
import { I18n } from 'aws-amplify';
import { useSelector } from 'react-redux'

import { activeWarehouses } from '../../../selectors/warehouses';
import { country as countrySelector } from '../../../selectors/company';
import { hasPermissionTo, isAdmin as isAdminSelector } from '../../../selectors/auth';
import { stationWarehouse } from '../../../selectors/app';

import { Tooltip, Icon } from '@alegradev/smile-ui-react';

const WarehouseFilter = ({ filters, setFilters }) => {
    const [selectedWarehouse, setSelectedWarehouse] = useState({ name: capitalize(I18n.get("all", "Todas")), });
    const warehouses = useSelector(activeWarehouses);
    const isAdmin = useSelector(isAdminSelector);
    const currentWarehouse = useSelector(stationWarehouse);
    const can = useSelector(hasPermissionTo);
    const warehousesOptions = (get(warehouses, 'length') > 1 && (isAdmin || can('index', 'warehouses')))
        ? [{ id: warehouses.length + 1, name: capitalize(I18n.get("all", "Todas")), }, ...warehouses]
        : warehouses.sort((x, y) => get(x, 'id') === get(currentWarehouse, 'id')
            ? -1
            : get(y, 'id') === get(currentWarehouse, 'id') ? 1 : 0);

    const country = useSelector(countrySelector);
    const setWarehouse = (warehouse) => {
        if (!isAdmin && !can('index', 'warehouses'))
            return null;
        setFilters({
            ...filters,
            idWarehouse: get(warehouse, 'id', null) === get(warehouses, 'length') + 1 ? null : get(warehouse, 'id', null)
        })
        setSelectedWarehouse(warehouse)
    }

    const renderTooltipMessage = (warehouses) => {
        const url = country === 'spain' ? 'https://ayuda.alegra.com/es/gestiona-tu-inventario-en-diferentes-almacenes-en-el-sistema-punto-de-venta-software-tpv' : 'https://ayuda.alegra.com/es/gestiona-tu-inventario-en-diferentes-bodegas-almacenes-dep%C3%B3sitos-1'
        if (!isAdmin)
            return (I18n.get('warehouseChangeError', 'No tienes los permisos para ver productos de otras bodegas'))
        if (!(get(warehouses, 'length') > 1))
            return (<>
                {I18n.get('productDistributionHelp.init', 'Conoce')}
                <a href={url} target="_blank" rel="noreferrer">
                    {I18n.get('productDistributionHelp.mid', ' cómo distribuir en diferentes bodegas')}
                </a>
                {I18n.get('productDistributionHelp.end', ' este producto.')}
            </>)
        return (I18n.get('warehouseChangeInfo', 'Cambia de bodega para visualizar los productos presentes en cada una.'))
    }

    return (
        <>
            <div className='d-flex align-items-center mb-3'>
            <label className="h4 text-muted mb-0">{capitalize(I18n.get('warehouse', 'bodega'))}</label>
            <Tooltip
                overlay={renderTooltipMessage(warehousesOptions)}
            >
                <Icon icon='info-circle' extraClass="icon-primary" size='small' />
            </Tooltip>
            </div>
            <Form
                onSubmit={{}}
            >
                {() => (
                    <Field
                        name={`warehouse`}
                        component={renderSelect}
                        className="col-4 p-0 m-0"
                        options={warehousesOptions}
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.id}
                        placeholder={get(selectedWarehouse, 'name')}
                        placeholderColor="#474747"
                        onChange={option => setWarehouse(option)}
                        disabled={!isAdmin && !can('index', 'warehouses')}
                        initialValue={((!isAdmin && !can('index', 'warehouses')) || get(warehousesOptions, 'length') === 1) ? get(warehousesOptions, '0') : null}
                        height={"3rem"}
                    />
                )}
            </Form>
        </>
    )
}

WarehouseFilter.propTypes = {
    setFilters: PropsTypes.func,
    filters: PropsTypes.object,
}

export default WarehouseFilter;