import Dexie from 'dexie'
import * as persistDB from 'idb-keyval'

const db = new Dexie('posv2')

export const startDB = async () => {
  if (!db.isOpen()) {
    db.version(3).stores({
      items: 'id, name, itemCategory.id, barcode',
      invoices: 'id, timestamp, client.id',
      contacts: 'id, name, identification, offline, [name+identification]',
      pendingInvoices: 'id, name',
      invoicesFe: 'id, timestamp',
      totalToCollect: 'id',
    });

    await db.open();
  }
}

export const cleanDB = async () => {
  persistDB.clear()
  await Promise.all([
    db.items.clear(),
    db.invoices.clear(),
    db.contacts.clear(),
    db.pendingInvoices.clear(),
    db.invoicesFe.clear(),
    db.totalToCollect.clear(),
  ]);
}

export const deleteDB = async () => {
  await db.delete()
}

export default db;