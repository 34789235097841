import React from 'react';
import { I18n } from '@aws-amplify/core';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { BigNumber } from "bignumber.js";
import dayjs from 'dayjs'

import { decimalPrecision, dateFormat as dateFormatSelector } from '../../selectors/company';

import { Icon } from '@alegradev/smile-ui-react';
import { setPrint } from '../../reducers/print';

const Payment = ({ payment, idSelected, onSelect }) => {
  const dispatch = useDispatch();
  const decimal = useSelector(decimalPrecision);
  const dateFormat = useSelector(dateFormatSelector);

  if (!payment) return null;

  const getNumber = () => {
    const number = !!get(payment, 'numberTemplate', null) 
      ? get(payment, 'numberTemplate.number', ''): get(payment, 'number', '');
    const prefix = !!get(payment, 'numberTemplate', null) 
      ? get(payment, 'numberTemplate.prefix', ''): get(payment, 'prefix', '');

    return `${prefix || ''}${number || ''}`
  }

  const handleSelect = () => {
    if(payment) {
      onSelect(payment);
      dispatch(setPrint({ type: 'payment', value: payment }));
    }
  }

  return (
    <div 
      className={`payment p-3 d-flex justify-content-between align-items-center ${+idSelected === +payment.id ? 'selected' : ''}`}
      onClick={handleSelect}
    >
      <div className="d-flex align-items-center w-75">
        <div className="payment-type mr-2">
          {payment.type === 'in' && (
            <Icon icon='circle-arrow-down-left' extraClass="icon-primary"/>
          )}
          {payment.type === 'out' && (
            <Icon icon='circle-arrow-up-right' extraClass="icon-danger"/>
          )}
        </div>

        <div className="d-flex flex-column">
          <div className="title text-break text-nowrap text-truncate text-capitalize-first">
            {getNumber()}
          </div>
          <div className="text-wrap text-capitalize-first h4">
            {dayjs(get(payment, 'date', '')).format(!!dateFormat ? dateFormat.toUpperCase() : I18n.get('dateFormat', 'DD/MM/YYYY'))}
          </div>
        </div>

      </div>
      <div className="w-25">
        <div className="price text-nowrap text-capitalize-first text-right">
          {new BigNumber(payment.amount).toFormat(decimal)}
        </div>
      </div>
    </div>
  )
}

Payment.propTypes = {
  payment: PropTypes.object.isRequired,
  idSelected: PropTypes.any,
  onSelect: PropTypes.func.isRequired,
}

export default Payment;
