import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { BigNumber } from 'bignumber.js';
import dayjs from 'dayjs';

import {
  decimalPrecision,
  dateFormat as dateFormatSelector,
} from '../../selectors/company';
import { Button } from '@alegradev/smile-ui-react';
import { setTypeToPrint } from '../../reducers/print';

const DetailBody = ({ payment }) => {
  const dispatch = useDispatch();
  const decimal = useSelector(decimalPrecision);
  const dateFormat = useSelector(dateFormatSelector);

  if (!payment) return null;

  return (
    <div className='p-4 position-relative overflow-hidden'>
      <div>
        <table className='detail-info-table rounded-lg'>
          <tbody>
            <tr>
              <td>{I18n.get('type', 'tipo')}</td>
              <td className='flex justify-content-between items-center'>
                <div
                  className={`badge m-0 font-weight-normal p-2
                  badge-${get(payment, 'type') === 'in' ? 'primary' : 'danger'}`}
                >
                  {get(payment, 'type') === 'in'
                    ? I18n.get('inType', 'ingreso')
                    : I18n.get('outType', 'egreso')}
                </div>
                <Button
                  onClick={() => dispatch(setTypeToPrint('payment'))}
                  size='small'
                  emphasis='outline'
                  text={I18n.get('print', 'Imprimir')}
                  leftIcon='printer'
                />
              </td>
            </tr>
            <tr>
              <td>
                {get(payment, 'type') === 'in'
                  ? I18n.get('inAmount', 'valor ingresado')
                  : I18n.get('outAmount', 'valor extraído')}
              </td>
              <td>
                {new BigNumber(get(payment, 'amount', 0)).toFormat(decimal)}
              </td>
            </tr>
            <tr>
              <td>{I18n.get('creation', 'creación')}</td>
              <td>
                {dayjs(get(payment, 'date', '')).format(
                  !!dateFormat
                    ? dateFormat.toUpperCase()
                    : I18n.get('dateFormat', 'DD/MM/YYYY')
                )}
              </td>
            </tr>
            {!!get(payment, 'client', null) && (
              <tr>
                <td>{I18n.get('client', 'cliente')}</td>
                <td>{get(payment, 'client.name', '')}</td>
              </tr>
            )}
            {!!get(payment, 'categories', null) &&
              payment.categories.map((category) => (
                <tr key={category.id}>
                  <td>{I18n.get('concept', 'concepto')}</td>
                  <td>{get(category, 'name', '')}</td>
                </tr>
              ))}
            <tr>
              <td>{I18n.get('observations', 'observaciones')}</td>
              <td>{get(payment, 'observations', '')}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

DetailBody.propTypes = {
  payment: PropTypes.object,
};

export default DetailBody;
