import React from 'react'
import { NavLink } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';
import { useSelector } from 'react-redux';

import { country as countrySelector, idCompanySelector, companySelector } from '../../selectors/company';

import { Tooltip, Icon } from '@alegradev/smile-ui-react';
import { userIsElegibleForTip } from './utils';

const SettingsMenu = () => {
  const country = useSelector(countrySelector);
  const idCompany = useSelector(idCompanySelector)
  const { registryDate } = useSelector(companySelector);

  return (
    <div className="settings-menu container d-flex flex-wrap p-5 
      justify-content-center justify-content-sm-between align-items-start text-left"
    >
      <div className="settings-menu__option d-flex flex-column shadow">
        <div className="settings-menu__option-header text-capitalize-first text-primary">
          {I18n.get('business', 'negocio')}
        </div>
        <div className="settings-menu__option-description text-muted text-capitalize-first">
          {I18n.get('companySettingInfo', 'configura la información de tu empresa y adapta Alegra a tu negocio')}
        </div>

        <div className="settings-menu__option-options d-flex flex-column">
          <NavLink to="settings/company">{capitalize(I18n.get('myBusiness', 'mi negocio'))}</NavLink>
          {(country === 'colombia' || country === 'spain') && (
            <NavLink to="settings/general">{capitalize(I18n.get('generalFeatures', 'funciones generales'))}</NavLink>
          )}

        </div>
      </div>

      <div className="settings-menu__option d-flex flex-column shadow">
        <div className="settings-menu__option-header text-capitalize-first text-primary">
          {I18n.get('station', 'terminal')}
        </div>
        <div className="settings-menu__option-description text-muted text-capitalize-first">
          {I18n.get('stationSettingInfo', 'administra las terminales que utilizas en tus puntos de ventas')}
        </div>

        <div className="settings-menu__option-options d-flex flex-column">
          <NavLink to="settings/station">{capitalize(I18n.get('actualStation', 'Terminal actual'))}</NavLink>
          <NavLink to="settings/stations">{capitalize(I18n.get('manageStations', 'gestionar terminales'))}</NavLink>
        </div>
      </div>

      <div className="settings-menu__option d-flex flex-column shadow">
        <div className="settings-menu__option-header text-capitalize-first text-primary">
          {I18n.get('sellDetails', 'Detalles en ventas')}
        </div>
        <div className="settings-menu__option-description text-muted text-capitalize-first">
          {I18n.get('sellDetailsInfo', 'gestiona los datos relacionados con el registro de tus ventas')}
        </div>

        <div className="settings-menu__option-options d-flex flex-column">
          <NavLink to="settings/sellers">{capitalize(I18n.get('mySellers', 'mis vendedores'))}</NavLink>
        </div>

        {userIsElegibleForTip({ country, idCompany }) && <div className="settings-menu__option-options d-flex flex-column">
          <NavLink to="settings/sellPreferences">
            {capitalize(I18n.get('sellPreferences', 'Preferencias de venta'))}
          </NavLink>
        </div>}
      </div>


      <div className="settings-menu__option d-flex flex-column shadow">
        <div className="settings-menu__option-header text-capitalize-first text-primary">
          {I18n.get('invoices', 'Facturas')}
        </div>
        <div className="settings-menu__option-description text-muted text-capitalize-first">
          {I18n.get('invoicesSettingInfo', 'configura los documentos de venta que usas en tu negocio')}
        </div>
        <div className="settings-menu__option-options d-flex flex-column">
          {country === 'colombia' &&
            <NavLink to="settings/invoices">{capitalize(I18n.get('myInvoices', 'mis facturas'))}</NavLink>
          }
          <NavLink to="settings/print">{capitalize(I18n.get('printTemplate', 'plantilla de impresión'))}</NavLink>

          <a href={`${process.env.REACT_APP_ALEGRA}resolution`} target="_blank" rel="noreferrer">
            <Tooltip
              overlay={I18n.get("numerationsSettingTooltip", 'Gestiona desde "Alegra Contabilidad" las numeraciones que usas para facturar en tu negocio.')}
            >
              <div className='d-flex align-items-center'>
                {I18n.get('numerations', 'Numeraciones')}
                <Icon icon='info-circle' extraClass="icon-primary ml-1" size='small' />
              </div>
            </Tooltip>
          </a>
          <a href={`${process.env.REACT_APP_ALEGRA}tax`} target="_blank" rel="noreferrer">
            <Tooltip
              overlay={I18n.get("taxesSettingTooltip", 'Configura desde "Alegra Contabilidad" los impuestos que necesitas para facturar en tu punto de venta.')}
            >
              <div className='d-flex align-items-center'>
                {I18n.get('taxes', 'Impuestos')}
                <Icon icon='info-circle' extraClass="icon-primary ml-1" size='small' />
              </div>
            </Tooltip>
          </a>
        </div>
      </div>

      {
        country !== 'spain' && (
          <div className="settings-menu__option d-flex flex-column shadow">
            <div className="settings-menu__option-header text-capitalize-first text-primary">
              {I18n.get('moreSettings', 'más configuraciones')}
            </div>
            <div className="settings-menu__option-description text-muted text-capitalize-first">
              {I18n.get('moreSettingInfo', 'puedes encontrar más configuraciones')}
            </div>

            <div className="settings-menu__option-options d-flex flex-column">
              <a className="btn-link" href={`${process.env.REACT_APP_ALEGRA}configuration`} target="_blank" rel="noreferrer">
                {I18n.get('goToAlegraAccounting', 'Ir a Alegra Contabilidad')}
              </a>
            </div>
          </div>
        )
      }
    </div>)
};

export default SettingsMenu;