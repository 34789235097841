import { useEffect, useRef, useState } from 'react';
import { showCreditLimitField } from '../../components/forms/contact/utils';
import { useDispatch, useSelector } from 'react-redux';
import { addTotalToCollect } from '../../reducers/totalToCollect';
import ReportsAPI from '../../reducers/balanceByClient';
import { allTotalToCollectData } from '../../selectors/totalToCollect';
import { useConnectionStatus } from '../useConnectionStatus';
import { get } from 'lodash';

const useTotalToCollect = ({ country, client }) => {
  const connectionStatus = useConnectionStatus();
  const [isTotalToCollectLoading, setIsTotalToCollectLoading] = useState(false);
  const [totalToCollect, setTotalToCollect] = useState(null);
  const balaceByClientRef = useRef(false);
  const allTotalToCollect = useSelector(allTotalToCollectData);

  const dispatch = useDispatch();
  const getLocalBalance = (clientId) => {
    const foundItem = allTotalToCollect.find((item) => item.id === clientId);
    return foundItem ? foundItem.totalToCollect : null;
  };

  const getBalanceByClient = async () => {
    setIsTotalToCollectLoading(true);
    try {
      let amount = null;
      const clientId = get(client, 'id', null);

      if (connectionStatus) {
        const response = await ReportsAPI.get(
          `client_id=${clientId}&balance_type=totalToCollect`
        );
        amount = get(response, 'data.totalToCollect', null);

        dispatch(
          addTotalToCollect({
            id: clientId,
            totalToCollect: amount,
          })
        );
      } else {
        amount = getLocalBalance(clientId);
      }

      setTotalToCollect(amount);
    } catch (error) {
      console.error('Error en el proceso:', error);
      const clientId = get(client, 'id', null);
      setTotalToCollect(getLocalBalance(clientId));
    } finally {
      setIsTotalToCollectLoading(false);
    }
  };

  useEffect(() => {
    if (!balaceByClientRef.current && showCreditLimitField(country)) {
      getBalanceByClient();
      balaceByClientRef.current = true;
    }
  }, []);

  return { totalToCollect, isTotalToCollectLoading };
};

export default useTotalToCollect;
