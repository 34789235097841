import { I18n } from '@aws-amplify/core';
import { get, capitalize, isNull, isUndefined, isArray } from 'lodash';
import { v4 } from 'uuid';
import { COUNTRIES, COUNTRIES_NAMES } from '../../../utils/enums/countries';
import { regimes } from '../../countriesData/colombia/regimes';

const validateCommom = (values) => {

  let errors = {};

  const name = get(values, 'firstName', '');
  const email = get(values, 'email', '');

  if (!name)
    errors.firstName = capitalize(I18n.get('youMustIndicateTheNameOfYourClient', 'Debes indicar el nombre de tu cliente'));

  if (!!email) {
    if (!/^[^@]+@[^@]+\.[a-zA-Z]{2,}$/.test(email)) {
      errors.email = capitalize(I18n.get('KeepTheFormEmail', 'Conserva la forma: ejemplo@correo.com'));
    } else if (!/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(email)) {
      errors.email = capitalize(I18n.get('avoidUsingSpacesAccentsAndSpecialSigns', 'Evite el uso de espacios, acentos y signos especiales'));
    }
  }
  return errors;
}

const validateColombia = (values, { isElectronic }) => {
  let errors = {}

  const identificationType = get(values, 'identification.type.key', '');
  const identificationNumber = get(values, 'identification.number', '');
  const lastName = get(values, 'lastName', '');
  const kindOfPerson = get(values, 'kindOfPerson.key', '');
  const completeName = (identificationType === 'NIT' && kindOfPerson === 'PERSON_ENTITY')
    || (identificationType !== 'NIT' && identificationType !== 'FOREIGN_NIT');

  if (!identificationType)
    errors.identification = { ...errors.identification, type: capitalize(I18n.get('selectTheIdentificationType', 'seleccione el tipo de identificación')) };

  if (!identificationNumber)
    errors.identification = { ...errors.identification, number: capitalize(I18n.get('enterAnID', 'Ingresa una identificación')) };

  if (identificationNumber && ['RC', 'NIT', 'CC', 'NUIP', 'TI'].includes(identificationType)) {
    const isCorrectFormat = /^[0-9]+$/.test(identificationNumber);
    if (!isCorrectFormat)
      errors.identification = { ...errors.identification, number: I18n.get('onlyNumericValuesAreSupported', 'Solo se admiten valores numéricos') };
  }

  if (!lastName && completeName)
    errors.lastName = capitalize(I18n.get('youMustIncludeAtLeastOneLastName', 'Debe incluir al menos un apellido'));

  if (isElectronic) {
    const foreignId = identificationType === 'DIE' || identificationType === 'PP' || identificationType === 'TE' || identificationType === 'FOREIGN_NIT';
    // const showCountry = !!identificationType && foreignId;

    // const country = get(values, 'address.country.key', '');
    const combinedAddress = get(values, 'address.combined.key', '');
    const address = get(values, 'address.address', '');
    const regime = get(values, 'regime.key', '');
    const isRequired = () => {
      if (address || combinedAddress) {
        return true;
      }
      return false;
    }
    if (!kindOfPerson && identificationType === 'NIT')
      errors.kindOfPerson = capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio'));
    if (!regime && identificationType === 'NIT')
      errors.regime = capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio'));

    if (!foreignId) {
      if (isRequired()) {
        if (!combinedAddress)
          errors.address = { ...errors.address, combined: capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')) };
        if (!address)
          errors.address = { ...errors.address, address: capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')) };
      }
    }
  }
  return errors
}

const validateSpain = (values) => {
  let errors = {};

  const identificationType = get(values, 'identification.type.key', '');
  const identificationNumber = get(values, 'identification.number', '');
  const combinedAddress = get(values, 'address.combined.key', '');
  const address = get(values, 'address.address', '');
  const postalCode = get(values, 'address.postalCode', '');
  const country = get(values, 'address.country.key', '');
  
  if (!identificationType)
    errors.identification = {
      ...errors.identification,
      type: capitalize(
        I18n.get(
          'selectTheIdentificationType',
          'seleccione el tipo de identificación'
        )
      ),
    };
  if (
    (identificationType === 'DNI' ||
      identificationType === 'NIF' ||
      identificationType === 'NIE') &&
    identificationNumber.length < 9
  )
    errors.identification = {
      ...errors.identification,
      number:
        capitalize(
          I18n.get(
            'theMinimumSizeForThisFieldIs',
            'El tamaño minimo para este campo es de'
          )
        ) + ' 9.',
    };
  if (
    (identificationType === 'DNI' ||
      identificationType === 'NIF' ||
      identificationType === 'NIE') &&
    identificationNumber.length > 9
  )
    errors.identification = {
      ...errors.identification,
      number:
        capitalize(
          I18n.get(
            'theMaximumSizeForThisFieldIs',
            'El tamaño maximo para este campo es de'
          )
        ) + ' 9.',
    };
  if (
    identificationType === 'DNI' &&
    !/^\d{8}[a-zA-Z]$/.test(identificationNumber)
  ) {
    errors.identification = {
      ...errors.identification,
      number: I18n.get(
        'theDNIhasEightNumbersAndOneDigitVerificationCode',
        'Debe tener ocho números y una letra.'
      ),
    };
  }

  if (
    identificationType === 'NIF' &&
    !/^[xyzbXYZB]\d{7}[0-9a-zA-Z]$/.test(identificationNumber)
  )
    errors.identification = {
      ...errors.identification,
      number: I18n.get(
        'mustHaveALetterAndSevenNumbersAndOneVerificationCode',
        'Debe tener una letra, siete números y un dígito de control.'
      ),
    };
  if (
    identificationType === 'NIE' &&
    !/^[xyzXYZ]\d{7}[0-9a-zA-Z]$/.test(identificationNumber)
  )
    errors.identification = {
      ...errors.identification,
      number: I18n.get(
        'mustHaveALetterAndSevenNumbersAndOneVerificationCode',
        'Debe tener una letra, siete números y un dígito de control.'
      ),
    };

  if(identificationType === 'NIF' && !combinedAddress)
    errors.address = {
      ...errors.address,
      combined: capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      ),
    };
  if(identificationType === 'NIF' && !address)
    errors.address = {
      ...errors.address,
      address: capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      ),
    };
  if(postalCode){
    if(postalCode.length < 5)
      errors.address = {
        ...errors.address,
        postalCode: capitalize(
          I18n.get('theMinimumSizeForThisFieldIs', 'El tamaño minimo para este campo es de')
        ) + ' 5.',
      };
    if(postalCode.length > 5)
      errors.address = {
        ...errors.address,
        postalCode: capitalize(
          I18n.get('theMaximumSizeForThisFieldIs', 'El tamaño maximo para este campo es de')
        ) + ' 5.',
      };
    if (!/^\d{5}$/.test(postalCode)) {
      errors.address = {
        ...errors.address,
        postalCode: I18n.get(
          'thePostalCodeMustHaveFiveNumbers',
          'Debe tener cinco números.'
        ),
      };
    }
  }

  if(identificationType === 'EXT' && !country)
    errors.address = {
      ...errors.address,
      country: capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      ),
    };
  return errors;
};

const validateCostaRica = values => {
  let errors = {};

  const identificationType = get(values, 'identification.type.key', '');
  const identificationNumber = get(values, 'identification.number', '');

  // if (!identificationType)
  //   errors.identification = { ...errors.identification, type: capitalize(I18n.get('selectTheIdentificationType', 'seleccione el tipo de identificación')) };

  if (identificationType === 'CF' && identificationNumber.length < 9)
    errors.identification = { ...errors.identification, number: capitalize(I18n.get('theMinimumSizeForThisFieldIs', 'El tamaño minimo para este campo es de')) + ' 9.' };
  if (identificationType === 'CF' && identificationNumber.length > 9)
    errors.identification = { ...errors.identification, number: capitalize(I18n.get('theMaximumSizeForThisFieldIs', 'El tamaño maximo para este campo es de')) + ' 9.' };

  if (identificationType === 'CJ' && !/^[^-]+$/.test(identificationNumber))
    errors.identification = { ...errors.identification, number: capitalize(I18n.get('theLegalIDMustBeWithoutHyphens', 'La cédula jurídica debe estar sin guiones.')) };
  if (identificationType === 'CJ' && identificationNumber.length < 10)
    errors.identification = { ...errors.identification, number: capitalize(I18n.get('theMinimumSizeForThisFieldIs', 'El tamaño minimo para este campo es de')) + ' 10.' };
  if (identificationType === 'CJ' && identificationNumber.length > 10)
    errors.identification = { ...errors.identification, number: capitalize(I18n.get('theMaximumSizeForThisFieldIs', 'El tamaño maximo para este campo es de')) + ' 10.' };

  if (identificationType === 'DIMEX' && !/^[1-9]+[0-9]*$/.test(identificationNumber))
    errors.identification = { ...errors.identification, number: capitalize(I18n.get('theDIMEXIsANumericalValue', 'El DIMEX es un valor numérico, sin ceros al inicio y sin guiones.')) };
  if (identificationType === 'DIMEX' && identificationNumber.length < 11)
    errors.identification = { ...errors.identification, number: capitalize(I18n.get('theMinimumSizeForThisFieldIs', 'El tamaño minimo para este campo es de')) + ' 11.' };
  if (identificationType === 'DIMEX' && identificationNumber.length > 12)
    errors.identification = { ...errors.identification, number: capitalize(I18n.get('theMaximumSizeForThisFieldIs', 'El tamaño maximo para este campo es de')) + ' 12.' };

  if (identificationType === 'NITE' && !/^[^-]+$/.test(identificationNumber))
    errors.identification = { ...errors.identification, number: capitalize(I18n.get('theNITEMustBeWithoutHyphens', 'El NITE debe estar sin guiones.')) };
  if (identificationType === 'NITE' && identificationNumber.length < 10)
    errors.identification = { ...errors.identification, number: capitalize(I18n.get('theMinimumSizeForThisFieldIs', 'El tamaño minimo para este campo es de')) + ' 10.' };
  if (identificationType === 'NITE' && identificationNumber.length > 10)
    errors.identification = { ...errors.identification, number: capitalize(I18n.get('theMaximumSizeForThisFieldIs', 'El tamaño maximo para este campo es de')) + ' 10.' };

  if (identificationType === 'PE' && identificationNumber.length > 20)
    errors.identification = { ...errors.identification, number: capitalize(I18n.get('theMaximumSizeForThisFieldIs', 'El tamaño maximo para este campo es de')) + ' 20.' };

  // if (!identificationNumber)
  //   errors.identification = { ...errors.identification, number: capitalize(I18n.get('enterAnID', 'Ingresa una identificación')) };

  const combinedAddress = get(values, 'address.combined.key', '');
  const neighborhood = get(values, 'address.neighborhood.value', '');
  const address = get(values, 'address.address', '');

  const foreignId = identificationType === 'PE';

  if (!foreignId) {
    if (!combinedAddress)
      errors.address = { ...errors.address, combined: capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')) };
    if (!neighborhood)
      errors.address = { ...errors.address, neighborhood: capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')) };
  }
  if (!address)
    errors.address = { ...errors.address, address: capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')) };

  return errors;
}

const validateArgentina = (values, { isElectronic }) => {
  let errors = {};

  const identificationType = get(values, 'identification.type.key', '');
  const identificationNumber = get(values, 'identification.number', '');
  const ivaCondition = get(values, 'ivaCondition.key', '');

  if (!identificationType)
    errors.identification = { ...errors.identification, type: capitalize(I18n.get('selectTheIdentificationType', 'seleccione el tipo de identificación')) };

  if (identificationType === 'CUIT' && !/^[0-9]+[0-9\\-]+[0-9]+$/.test(identificationNumber))
    errors.identification = { ...errors.identification, number: capitalize(I18n.get('CUITIdentificationIsANumericalValue', 'El CUIT debe ser un valor numérico, puede tener guiones')) };

  if (!identificationNumber)
    errors.identification = { ...errors.identification, number: capitalize(I18n.get('enterAnID', 'Ingresa una identificación')) };

  if (!ivaCondition)
    errors.ivaCondition = capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio'));

  if (isElectronic && !["CI", "OTHER"].includes(identificationType)) {
    const province = get(values, 'address.province.value', '');
    const city = get(values, 'address.city.value', '');
    const address = get(values, 'address.address', '');

    if (!province)
      errors.address = { ...errors.address, province: capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')) };
    if (!city)
      errors.address = { ...errors.address, city: capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')) };
    if (!address)
      errors.address = { ...errors.address, address: capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')) };
  }

  return errors;
}

const validatePeru = values => {
  let errors = {};

  const identificationType = get(values, 'identification.type.key', '');
  const identificationNumber = get(values, 'identification.number', '');

  const validRucStarts = ['10', '20', '15', '16', '17'];

  if (!identificationType)
    errors.identification = { ...errors.identification, type: capitalize(I18n.get('selectTheIdentificationType', 'seleccione el tipo de identificación')) };

  if (identificationType === 'RUC' && identificationNumber.length < 11)
    errors.identification = { ...errors.identification, number: capitalize(I18n.get('theMinimumSizeForThisFieldIs', 'El tamaño minimo para este campo es de')) + ' 11.' };
  if (identificationType === 'RUC' && identificationNumber.length > 11)
    errors.identification = { ...errors.identification, number: capitalize(I18n.get('theMaximumSizeForThisFieldIs', 'El tamaño maximo para este campo es de')) + ' 11.' };
  if (identificationType === 'RUC' && !validRucStarts.some(word => identificationNumber.startsWith(word)))
    errors.identification = { ...errors.identification, number: (I18n.get('RUCNotValidHelp', 'El RUC ingresado tiene un formato no válido')) };


  if (identificationType === 'SIN RUC' && identificationNumber.length > 15)
    errors.identification = { ...errors.identification, number: capitalize(I18n.get('theMaximumSizeForThisFieldIs', 'El tamaño maximo para este campo es de')) + ' 15.' };

  if (identificationType === 'CDI' && identificationNumber.length > 15)
    errors.identification = { ...errors.identification, number: capitalize(I18n.get('theMaximumSizeForThisFieldIs', 'El tamaño maximo para este campo es de')) + ' 15.' };

  if (identificationType === 'PP' && identificationNumber.length > 12)
    errors.identification = { ...errors.identification, number: capitalize(I18n.get('theMaximumSizeForThisFieldIs', 'El tamaño maximo para este campo es de')) + ' 12.' };

  if (identificationType === 'CE' && identificationNumber.length > 12)
    errors.identification = { ...errors.identification, number: capitalize(I18n.get('theMaximumSizeForThisFieldIs', 'El tamaño maximo para este campo es de')) + ' 12.' };

  if (identificationType === 'DNI' && identificationNumber.length < 8)
    errors.identification = { ...errors.identification, number: capitalize(I18n.get('theMinimumSizeForThisFieldIs', 'El tamaño minimo para este campo es de')) + ' 8.' };
  if (identificationType === 'DNI' && identificationNumber.length > 8)
    errors.identification = { ...errors.identification, number: capitalize(I18n.get('theMaximumSizeForThisFieldIs', 'El tamaño maximo para este campo es de')) + ' 8.' };

  if (!identificationNumber)
    errors.identification = { ...errors.identification, number: capitalize(I18n.get('enterAnID', 'Ingresa una identificación')) };

  return errors;
}

const validateDominicana = values => {

  let errors = {};
  const identificationType = get(values, 'identification.type', '');
  const identificationNumber = get(values, 'identification.number', '');
  const isElectronic = get(values, 'isElectronic', '');
  const isCorrectFormat = !!identificationType ? true : identificationNumber?.match(/^(?:\d{9}|\d{11})$/)

  if (!identificationNumber && !!isElectronic)
    errors.identification = { ...errors.identification, number: capitalize(I18n.get('enterAnID', 'Ingresa una identificación')) };

  if (!!identificationNumber && !isCorrectFormat)
    errors.identification = { ...errors.identification, number: capitalize(I18n.get('identificationDefaultError', 'Debe ser un número de 9 a 11 dígitos')) }

  if (identificationType && identificationType.key === "RNC" && identificationNumber && identificationNumber.length !== 9)
    errors.identification = { ...errors.identification, number: I18n.get('identificationFailed.rnc', 'El RNC debe ser un valor numérico de 9 dígitos de longitud') }

  if (identificationType && identificationType.key === "CED" && identificationNumber && identificationNumber.length !== 11)
    errors.identification = { ...errors.identification, number: I18n.get('identificationFailed.ced', 'La cédula debe ser un valor numérico de 11 dígitos de longitud') }

  return errors;
}

export const validatePanama = (values) => {

  let errors = {};
  const identificationType = get(values, 'identification.type.key', '');
  const identificationNumber = get(values, 'identification.number', '');
  const identificationRuc = get(values, 'identification.ruc', '');
  const isCorrectRuc = get(values, 'isCorrectRuc', '');
  const identificationDv = get(values, 'identification.dv', '');
  const kindOfPerson = get(values, 'kindOfPerson.key', '');
  const lastName = get(values, 'lastName', '');
  const address = get(values, 'address.address', '');
  const combinedAddress = get(values, 'address.combined.code', '');
  const country = get(values, 'address.country.key', '');
  const phone = get(values, 'phonePrimary', '');
  const mobile = get(values, 'mobile', '');
  const isElectronic = get(values, 'isElectronic', '');
  // const isElectronic = true;

  const isRequiredField = () => {
    let isRequired = true;
    if (!identificationType && !kindOfPerson && !isElectronic) return false;
    if (identificationType === 'FINAL_CONSUMER' && kindOfPerson === '') isRequired = false;
    return isRequired;
  }

  const isRequiredFieldAdress = () => {
    let isRequired = true;
    if (!identificationType && !kindOfPerson && !isElectronic) return false;
    if (identificationType === 'FINAL_CONSUMER' || identificationType === 'FOREIGN' || identificationType === 'FOREIGN_COMPANY') isRequired = false;
    return isRequired;
  }

  if (!identificationType && identificationType !== 'FOREIGN' && isElectronic)
    errors.identification = { ...errors.identification, type: capitalize(I18n.get('selectTheIdentificationType', 'seleccione el tipo de identificación')) };

  if (!kindOfPerson && isRequiredField() && identificationType !== 'FOREIGN' && identificationType !== 'FOREIGN_COMPANY' && isElectronic)
    errors.kindOfPerson = capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio'));

  if (!identificationNumber && (identificationType === 'FOREIGN' || identificationType === 'FOREIGN_COMPANY'))
    errors.identification = { ...errors.identification, number: capitalize(I18n.get('enterAnID', 'Ingresa una identificación')) };

  if (!identificationRuc && isRequiredField() && identificationType !== 'FOREIGN' && identificationType !== 'FOREIGN_COMPANY')
    errors.identification = { ...errors.identification, ruc: capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')) };

  if (identificationRuc && isRequiredField) {
    const isCorrectFormat = identificationRuc.match(
      /^(([0-9]{1,10})|(E|N|PE)|(([0-9]|10|11|12|13)-NT)|(([0-9]|10|11|12|13)-N))-([0-9]{1,4})-([0-9]{1,9})$/g
    );
    if (!isCorrectFormat)
      errors.identification = { ...errors.identification, ruc: I18n.get('rucIsInvalidFormat', 'Ingrese un formato correcto') };
  }

  if (isCorrectRuc === false && isElectronic && (identificationType === 'TAXPAYER'))
    errors.identification = { ...errors.identification, ruc: I18n.get('rucIsInvalid', 'El RUC es inválido para el tipo de contribuyente que seleccionaste.') };

  if (!identificationDv && isRequiredField() && identificationType !== 'FOREIGN' && identificationType !== 'FOREIGN_COMPANY')
    errors.identification = { ...errors.identification, dv: capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')) };

  if (!lastName && kindOfPerson === 'PERSON_ENTITY')
    errors.lastName = capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio'));

  if (!address && isRequiredFieldAdress())
    errors.address = { ...errors.address, address: capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')) };

  if (!combinedAddress && isRequiredFieldAdress())
    errors.address = { ...errors.address, combined: capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')) };

  if (!country && identificationType === 'FOREIGN')
    errors.address = { ...errors.address, country: capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')) };

  if (phone && phone.length < 8) {
    errors.phonePrimary = capitalize(I18n.get('enterValidPhoneNumber', 'Ingrese un número de teléfono válido'));
  }

  if (mobile && mobile.length < 9) {
    errors.mobile = capitalize(I18n.get('enterValidPhoneNumber', 'Ingrese un número de teléfono válido'));
  }

  return errors;
}

export const validateMexico = (values) => {
  let errors = {};
  const identification = get(values, 'rfc', null);
  const fiscalId = get(values, 'fiscalId', null);
  const thirdType = get(values, 'thirdType', null);
  const regime = get(values, 'regime', null);


  const zipCode = get(values, 'address.zipCode', null);
  const country = get(values, 'address.country', null);
  

  if(thirdType === 'NATIONAL') {
    if (!identification)
      errors.rfc = capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio'));
    if (identification && identification.length < 12)
      errors.rfc = capitalize(`${I18n.get('theMinimumSizeForThisFieldIs', 'El tamaño minimo para este campo es de')} 12 ${I18n.get('characteres', 'caracteres')}`);
    if (identification && identification.length > 13)
      errors.rfc = capitalize(`${I18n.get('theMaximumSizeForThisFieldIs', 'El tamaño maximo para este campo es de')} 13 ${I18n.get('characteres', 'caracteres')}`);
  }

  if(thirdType === 'FOREIGN') {
    if(!fiscalId) {
      errors.fiscalId = capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio'));
    }
  }

  if(!regime) {
    errors.regime = capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio'));
  }

  if(zipCode) {
    if(zipCode.length !== 5 || !/^[0-9]+$/.test(zipCode)) {
      errors.address = { ...errors.address, zipCode: capitalize(I18n.get('theZipCodeMustBe5Digits', 'El código postal debe ser de 5 dígitos')) };
    }
  } else {
    errors.address = { ...errors.address, zipCode: capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')) };
  }

  if(!country) {
    errors.address = { ...errors.address, country: capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')) };
  }

  return errors;
}

const validateCountry = (values, { country, ...props }) => {
  switch (country) {
    case 'colombia':
      return validateColombia(values, props);
    case 'costaRica':
      return validateCostaRica(values, props);
    case 'spain':
      return validateSpain(values, props);
    case 'peru':
      return validatePeru(values, props);
    case 'argentina':
      return validateArgentina(values, props);
    case 'republicaDominicana':
      return validateDominicana(values, props);
    case 'panama':
      return validatePanama(values, props);
    case COUNTRIES.MEXICO:
      return validateMexico(values, props);
    default:
      return {};
  }
}

export const validate = (values, props) => {
  let errors = {
    ...validateCommom(values, props),
    ...validateCountry(values, props)
  };

  return errors;
}

export const validationPreviousCreation = (values, props) => {
  if (!get(values, 'accounting.accountReceivable') || !get(values, 'accounting.debtToPay'))
    throw new Error(I18n.get('contactAccountingError', 'Algo impidió traer la configuración contable para tu cliente, recarga e intenta crearlo de nuevo.'))

  if (props.country === 'republicaDominicana') {
    const identificationType = get(values, 'identification.type', '');
    const identificationNumber = get(values, 'identification.number', '');

    if (identificationType && identificationType.key === "RNC" && identificationNumber && identificationNumber.length !== 9)
      throw new Error(I18n.get('identificationFailed.rnc', 'El RNC debe ser un valor numérico de 9 dígitos de longitud'))
    if (identificationType && identificationType.key === "CED" && identificationNumber && identificationNumber.length !== 11)
      throw new Error(I18n.get('identificationFailed.ced', 'La cédula debe ser un valor numérico de 11 dígitos de longitud'))
  }
}

const colombiaValues = (values, { isElectronic }, newValues) => {

  const identificationType = get(values, 'identification.type.key', null);
  const identificationNumber = get(values, 'identification.number', null);
  const kindOfPerson = identificationType
    ? get(values, 'kindOfPerson.key', null)
    : isElectronic ? 'PERSON_ENTITY' : null;

  const completeName = kindOfPerson === "PERSON_ENTITY";

  const countryKey = get(values, 'address.country.key', null);
  const countryValue = get(values, 'address.country.value', null);
  const combinedAddress = get(values, 'address.combined.value', null);
  const zipCode = get(values, 'address.zipCode', null);
  const address = get(values, 'address.address', null);

  const foreignId = identificationType === 'DIE' || identificationType === 'PP' || identificationType === 'TE' || identificationType === 'FOREIGN_NIT';
  const showCountry = !!identificationType && foreignId;

  let newAddress = {
    country: showCountry ? countryValue : 'Colombia',
    zipCode: zipCode,
    address: address,
  }
  if (!!combinedAddress && (!foreignId || countryKey === 'COL')) {
    newAddress = {
      ...newAddress,
      city: get(values, 'address.combined.city', null),
      department: get(values, 'address.combined.department', null),
    }
  }

  let fiscalResponsabilities = [];
  const fiscalResponsabilitiesValues = get(values, 'fiscalResponsabilities', null);
  if (!!fiscalResponsabilitiesValues) {
    Object.keys(fiscalResponsabilitiesValues).map(key => {
      if (fiscalResponsabilitiesValues[key] === true)
        fiscalResponsabilities.push(key);
      return null;
    })
  }

  delete newValues.identification;

  return {
    ...newValues,
    nameObject: !!completeName
      ? {
        firstName: get(values, 'firstName', ''),
        secondName: get(values, 'secondName', ''),
        lastName: get(values, 'lastName', ''),
      } : null,
    name: !completeName ? get(values, 'firstName', '') : null,
    identificationObject: {
      type: identificationType,
      number: identificationNumber
    },
    kindOfPerson,
    address: newAddress,
    regime: get(values, 'regime.key', null),
    fiscalResponsabilities: isElectronic ? fiscalResponsabilities : null,
  }
}

const costaRicaValues = (values, _props, newValues) => {
  const identificationType = get(values, 'identification.type.key', null);
  const identificationNumber = get(values, 'identification.number', null);

  const foreignId = identificationType === 'PE';

  const combinedAddress = get(values, 'address.combined.value', null);
  const neighborhood = get(values, 'address.neighborhood.value', null);
  const address = get(values, 'address.address', null);

  let newAddress = { address };
  if (!!combinedAddress && !foreignId) {
    newAddress = {
      ...newAddress,
      district: get(values, 'address.combined.district', null),
      city: get(values, 'address.combined.city', null),
      department: get(values, 'address.combined.department', null),
      neighborhood,
    }
  }

  delete newValues.identification;
  return {
    ...newValues,
    identificationObject: {
      type: identificationType,
      number: identificationNumber
    },
    address: newAddress,
  }
}

const argentinaValues = (values, _props, newValues) => {
  const identificationType = get(values, 'identification.type.key', null);
  const identificationNumber = get(values, 'identification.number', null);

  delete newValues.identification;
  const transformedValues = {
    ...newValues,
    identificationObject: {
      type: identificationType,
      number: identificationNumber
    },
    ivaCondition: get(values, 'ivaCondition.key', null),
    address: {
      province: get(values, 'address.province.value', null),
      city: get(values, 'address.city.value', null),
      postalCode: get(values, 'address.postalCode', null),
      address: get(values, 'address.address', null),
    },
  }
  Object.keys(transformedValues.address).forEach(key => {
    if (isNull(transformedValues.address[key]) || isUndefined(transformedValues.address[key]))
      delete transformedValues.address[key];
  })
  return transformedValues;
}

const peruValues = (values, _props, newValues) => {
  const identificationType = get(values, 'identification.type.key', null);
  const identificationNumber = get(values, 'identification.number', null);

  const foreignId = !(identificationType === 'RUC' || identificationType === 'DNI');
  const showCountry = !!identificationType && foreignId;

  const countryKey = get(values, 'address.country.key', null);
  const countryValue = get(values, 'address.country.value', null);
  const combinedAddress = get(values, 'address.combined.id', null);
  const address = get(values, 'address.address', null);

  let newAddress = {
    country: showCountry ? countryValue : COUNTRIES_NAMES.PERU,
    address: address,
  }

  if (!!combinedAddress && (!foreignId || countryKey === 'PER')) {
    const urbanization = get(values, 'address.urbanization', null);

    newAddress = {
      ...newAddress,
      state: get(values, 'address.combined.department', null),
      city: get(values, 'address.combined.province', null),
      district: get(values, 'address.combined.district', null),
      urbanization,
      ubigeoCode: get(values, 'address.combined.ubigeo', null),
    }
  }

  return {
    ...newValues,
    identification: {
      type: identificationType,
      number: identificationNumber
    },
    address: newAddress,
  }
}

const dominicanaValues = (values, _props, newValues) => {
  const identificationType = get(values, 'identification.type.key', null);
  const identificationNumber = get(values, 'identification.number', null);

  const foreignId = !(identificationType === 'RNC' || identificationType === 'CED');
  const showCountry = !!identificationType && foreignId;

  const countryKey = get(values, 'address.country.key', null);
  const countryValue = get(values, 'address.country.value', null);
  const combinedAddress = get(values, 'address.combined.value', null);
  const address = get(values, 'address.address', null);

  let newAddress = {
    country: showCountry ? countryValue : 'República Dominicana',
    address: address,
    description: address,
  }

  if (!!combinedAddress && (!foreignId || countryKey === 'DOM')) {
    newAddress = {
      ...newAddress,
      municipality: get(values, 'address.combined.municipality', null),
      province: get(values, 'address.combined.province', null),
    }
  }

  const contact = {
    ...newValues,
    identification: {
      type: identificationType,
      number: identificationNumber
    },
    address: newAddress,
  }

  if (!contact.identification.type && !contact.identification.number) {
    delete contact.identification;
  }

  return contact;
}

const panamaValues = (values, _props, newValues) => {
  const identificationType = get(values, 'identification.type.key', null);
  const identificationNumber = get(values, 'identification.number', null);
  const identificationRuc = get(values, 'identification.ruc', null);
  const identificationDv = get(values, 'identification.dv', null);
  const kindOfPerson = get(values, 'kindOfPerson.key', null);
  const address = get(values, 'address.address', null);
  const country = get(values, 'address.country.value', null);
  const phone = get(values, 'phonePrimary', null);
  const mobile = get(values, 'mobile', '');

  let valueNames = {}

  if (kindOfPerson === "PERSON_ENTITY") {
    valueNames = {
      nameObject: {
        firstName: get(values, 'firstName', null),
        secondName: get(values, 'secondName', null),
        lastName: get(values, 'lastName', null),
        secondLastName: get(values, 'secondLastName', null),
      }
    }
  } else {
    valueNames = {
      name: get(values, 'firstName', null),
    }
  }

  return {
    ...newValues,
    ...valueNames,
    identification: (identificationType === 'FOREIGN' || identificationType === 'FOREIGN_COMPANY') ? identificationNumber : identificationRuc,
    identificationObject: {
      kindOfPerson,
      number: (identificationType === 'FOREIGN' || identificationType === 'FOREIGN_COMPANY') ? identificationNumber : identificationRuc,
      dv: identificationDv
    },
    address: {
      province: get(values, 'address.combined.provinceValue', null),
      district: get(values, 'address.combined.parentValue', null),
      township: get(values, 'address.combined.value', null),
      address,
      country
    },
    phonePrimary: phone,
    mobile,
    kindOfPerson,
    thirdType: identificationType
  }
}

const spainValues = (values, _props, newValues) => {
  const identificationType = get(values, 'identification.type.key', null);
  const identificationNumber = get(values, 'identification.number', null);
  const combinedAddress = get(values, 'address.combined', null);
  const country = get(values, 'address.country', null);
  let newAddress = {}
  if(combinedAddress){
    newAddress = {
      city: get(values, 'address.combined.municipality', null),
      province: get(values, 'address.combined.province', null),
      zipCode: get(values, 'address.postalCode', null),
      address: get(values, 'address.address', null),
    }
  }
  if(country){
    newAddress = {
      country: get(values, 'address.country.value', null),
      city: get(values, 'address.city',  null),
      address: get(values, 'address.address', null),
      zipCode: get(values, 'address.postalCode', null),
    }
  }
  return {
    ...newValues,
    identificationObject: {
      type: identificationType,
      number: identificationNumber,
    },
    identification: null,
    address: newAddress
  };
}

const mexicoValues = (values, _props, newValues) => {
  const identification = get(values, 'rfc', null);
  const fiscalId = get(values, 'fiscalId', null);
  const thirdType = get(values, 'thirdType', null);
  const regime = isArray(get(values, 'regime', null)) ? get(values, 'regime', null) : [get(values, 'regime', null)];
  const country = get(values, 'address.country', null);
  
  const regimeValues = regime.map(regimeOption => regimeOption.value);
  const favoriteRegime = regime.find(regimeOption => regimeOption?.favorite);

  return {
    ...newValues,
    identification,
    fiscalId,
    thirdType,
    regime: favoriteRegime ? favoriteRegime?.value : regimeValues[0],
    regimeObject: regimeValues,
    address: { ...newValues.address, country: get(country, 'key', 'MEX') },
  }
}

const commomValues = (values, { contact, isDuplicatedContact = false }) => ({
  id: !!contact ? contact.id : v4(),
  type: ['client'],
  offline: true,
  name: get(values, 'firstName', null),
  identification: get(values, 'identification', null),
  address: get(values, 'address', null),
  phonePrimary: get(values, 'phonePrimary', null),
  phoneSecondary: get(values, 'phoneSecondary', null),
  email: get(values, 'email', null),
  priceList: get(values, 'priceList.id', null),
  seller: get(values, 'seller.id', null),
  ignoreRepeated: isDuplicatedContact,
  term: get(values, 'term.id', null) === -1
    ? null : get(values, 'term.id', null),
  statementAttached: get(values, 'statementAttached', false) ? 'yes' : 'no',
  accounting: {
    accountReceivable: get(values, 'accounting.accountReceivable.id', null),
    debtToPay: get(values, 'accounting.debtToPay.id', null),
  },
})

export const transform = (values, props) => {
  let newValues = commomValues(values, props);

  switch (props.country) {
    case 'colombia':
      newValues = colombiaValues(values, props, newValues);
      break;
    case 'costaRica':
      newValues = costaRicaValues(values, props, newValues);
      break;
    case 'argentina':
      newValues = argentinaValues(values, props, newValues);
      break;
    case 'peru':
      newValues = peruValues(values, props, newValues);
      break;
    case 'republicaDominicana':
      newValues = dominicanaValues(values, props, newValues);
      break;
    case 'panama':
      newValues = panamaValues(values, props, newValues);
      break;
    case COUNTRIES.SPAIN:
      newValues = spainValues(values, props, newValues);
      break;
    case COUNTRIES.MEXICO:
      newValues = mexicoValues(values, props, newValues);
      break;
    default:
      break;
  }

  Object.keys(newValues).map(key => {
    if (isNull(newValues[key]) || isUndefined(newValues[key]))
      delete newValues[key];
    return null;
  })

  return newValues;
}

export const regimeOptions = (props) =>
  regimes.filter((regime) =>
    props.isElectronic
      ? regime.electronicInvoicingVersion === '2.1'
      : regime.electronicInvoicingVersion === '2.0'
  );

export const showCreditLimitField = (country) => {
    return [
      COUNTRIES.COLOMBIA,
      COUNTRIES.PANAMA,
      COUNTRIES.REPUBLICA_DOMINICANA,
      COUNTRIES.COSTA_RICA,
    ].includes(country);
  };