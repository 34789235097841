import React from 'react'

import NetworkStatus from './NetworkStatus'
import Shifts from './Shifts'
import Company from './Company'
import Items from './Items'
import Invoices from './Invoices'
import Refunds from './Refunds'
import Payments from './Payments'
import ItemsSns from './ItemsSns'
import ClientsSns from './ClientsSns'
import WarehousesSns from './WarehousesSns'
import NumerationsSns from './NumerationsSns'
import RefundsSns from './RefundsSns'
import MembershipSNS from './MembershipSNS'
import PricesList from './PricesList'
import ItemCategorySns from './ItemCategorySns'

const Subscriptions = () => {
  return (
    <>
      <NetworkStatus />
      <Shifts />
      <Company />
      <Items />
      <Invoices />
      <Refunds />
      <Payments />
      <ItemsSns />
      <ClientsSns />
      <WarehousesSns />
      <NumerationsSns />
      <RefundsSns />
      <MembershipSNS />
      <PricesList />
      <ItemCategorySns />
    </>
  )
}

export default Subscriptions;